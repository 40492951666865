import React from "react";
import FormMailer from "../components/FormMailer";
import { NavLink } from "react-router-dom";

const Sidebar = () => {
  return (
    <>
      <div className="py-3">
        <h3 className="pb-2 text-2xl font-bold font-primary text-primary">
          Courses Menu
        </h3>
        <p className="px-5 py-1 my-2 text-lg border border-dashed rounded-lg font-text text-iconColor2 border-iconColor2">
          <NavLink className="" to="/group-classes-students-age-7-18">
            Weekly/Regular Kids Programme
          </NavLink>
        </p>
        <p className="px-5 py-1 my-2 text-lg border border-dashed rounded-lg font-text text-iconColor2 border-iconColor2">
          <NavLink
            className=""
            to="/courses-and-programs/group-classes-for-adults"
          >
            Weekly/Regular Adults Programme
          </NavLink>
        </p>
        <p className="px-5 py-1 my-2 text-lg border border-dashed rounded-lg font-text text-iconColor2 border-iconColor2">
          <NavLink
            className=""
            to="/school-holiday-public-speaking-program-for-children"
          >
            School Holiday Programme (Kids)
          </NavLink>
        </p>
        <p className="px-5 py-1 my-2 text-lg border border-dashed rounded-lg font-text text-iconColor2 border-iconColor2">
          <NavLink
            className=""
            to="/presentation-skills-training-course-by-world-champion"
          >
            Intensive, Master-Class (Adults)
          </NavLink>
        </p>
        <div className="px-5 py-1 my-2 border border-dashed rounded-lg border-iconColor2">
          <p className="text-lg font-bold font-text text-iconColor2">
            Customised/Tailored Training
          </p>
          <p className="ml-3 text-lg font-text text-iconColor2">
            <NavLink
              className=""
              to="/courses-and-programs/premium-1-to-1-public-speaking-coaching"
            >
              Premium 1-to-1
            </NavLink>
          </p>
          <p className="ml-3 text-lg font-text text-iconColor2">
            <NavLink
              className=""
              to="/corporate-public-speaking-training-workshop"
            >
              Corporate Programmes
            </NavLink>
          </p>
          <p className="ml-3 text-lg font-text text-iconColor2">
            <NavLink
              className=""
              to="/public-speaking-training-for-schools-workshop"
            >
              School-Based Programmes
            </NavLink>
          </p>
        </div>
      </div>
      <div className="px-5 pt-5 mb-10 border border-gray-400 border-dashed rounded-lg">
        <h3 className="pb-2 text-2xl font-bold font-primary text-primary">
          Quick Query Form
        </h3>
        <FormMailer />
      </div>
    </>
  );
};

export default Sidebar;
