import React from "react";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <div className="py-20 bg-primary">
        <div className="container p-3 mx-auto">
          <div className="flex flex-col space-y-5 lg:flex-row lg:space-y-0">
            <div className="w-full lg:w-1/3">
              <h3 className="text-2xl font-bold text-white uppercase font-primary">
                Important Information
              </h3>
              <hr className="w-48 h-1 my-2 bg-white border-0 rounded" />
              <img
                className="py-3 h-32 w-auto"
                src="/images/psafooterlogo.png"
                alt=""
              />
              <p className="py-1 text-white">
                <NavLink to="/our-data-privacy-policy">
                  Data Protection Policy
                </NavLink>
              </p>
              <p className="py-1 text-white">
                <NavLink to="/terms-and-conditions">
                  Our Terms & Conditions
                </NavLink>
              </p>
              <p className="py-1 text-white">
                <NavLink to="/category/blog">Our Blog</NavLink>
              </p>
            </div>
            <div className="w-full lg:w-1/3">
              <h3 className="text-2xl font-bold text-white uppercase font-primary">
                Quick Links
              </h3>
              <hr className="w-48 h-1 my-2 bg-white border-0 rounded" />

              <p className="py-1 text-white">
                <NavLink to="/group-classes-students-age-7-18">
                  Weekly/Regular Kids Programme
                </NavLink>
              </p>
              <p className="py-1 text-white">
                <NavLink to="/courses-and-programs/group-classes-for-adults">
                  9 Week Program For Adults
                </NavLink>
              </p>
              <p className="py-1 text-white">
                <NavLink to="/school-holiday-public-speaking-program-for-children">
                  School Holiday Programme (Kids)
                </NavLink>
              </p>
              <p className="py-1 text-white">
                <NavLink to="/presentation-skills-training-course-by-world-champion">
                  Intensive, Master-Class (Adults)
                </NavLink>
              </p>
              <p className="py-1 text-white">
                <NavLink to="#">Customised/Tailored Training</NavLink>
              </p>
              <p className="py-1 ml-3 text-white">
                <NavLink to="/courses-and-programs/premium-1-to-1-public-speaking-coaching">
                  Premium 1-to-1
                </NavLink>
              </p>
              <p className="py-1 ml-3 text-white">
                <NavLink to="/corporate-public-speaking-training-workshop">
                  Corporate Programmes
                </NavLink>
              </p>
              <p className="py-1 ml-3 text-white">
                <NavLink to="/public-speaking-training-for-schools-workshop">
                  School-Based Programmes
                </NavLink>
              </p>
            </div>
            <div className="w-full lg:w-1/3">
              <h3 className="text-2xl font-bold text-white uppercase font-primary">
                Our Branches
              </h3>
              <hr className="w-48 h-1 my-2 bg-white border-0 rounded" />

              <div className="flex flex-col pt-5 space-y-5">
                <div className="flex space-x-3">
                  <div>
                    <span className="items-center text-4xl bg-white material-symbols-rounded">
                      {" "}
                      location_on{" "}
                    </span>
                  </div>
                  <div>
                    <h2 className="text-sm font-bold text-white font-primary">
                      Public Speaking Academy @Tampines 1A
                    </h2>
                    <p className="text-sm text-white font-text">
                      1A Tampines Street 92 #01-13B (Safra Tampines)
                      Singapore-528882.
                    </p>
                  </div>
                </div>
                <div className="flex space-x-3">
                  <div>
                    <span className="items-center text-4xl bg-white material-symbols-rounded">
                      {" "}
                      location_on{" "}
                    </span>
                  </div>
                  <div>
                    <h2 className="text-sm font-bold text-white font-primary">
                      Public Speaking Academy@Bukit Timah
                    </h2>
                    <p className="text-sm text-white font-text">
                      Coronation Shopping Plaza #02-27, 587 Bukit Timah Road,
                      Singapore 269707.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#052C45] mx-auto py-3 text-center text-white font-bold">
        @ Copyright 2018. All Rights Reserved.
      </div>
    </footer>
  );
};

export default Footer;
