import Footer from "../components/Footer";

import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function BookClass() {
  return (
    <>
      <Helmet>
        <title>Book Class | Public Speaking Academy</title>
        <meta name="description" content="Book class for PSA" />
      </Helmet>

      <section id="breadcrumb" className="bg-[#F5F5FC] py-1">
        <div className="container mx-auto">
          <div className="flex justify-between">
            <div>Book Class</div>
            <div>Home / book Class</div>
          </div>
        </div>
      </section>

      <section id="latest-blog">
        <div className="container mx-auto">
          <iframe
            src="https://booking.publicspeakingacademy.com.sg"
            frameborder="0"
            className=" w-full h-screen"
            height="100%"
          ></iframe>
        </div>
      </section>

      <Footer />
    </>
  );
}
