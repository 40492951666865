import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

export default function DarrenTay() {
  return (
    <>
      <Helmet>
        <title>Darren Tay - Master Trainer | Public Speaking Academy</title>
        <meta
          name="description"
          content="Public Speaking training, Led by Darren Tay, coaches you effective communication and presentation skills in group. Contact us at 6443 4049 today!"
        ></meta>
      </Helmet>

      <section id="breadcrumb" className="bg-[#F5F5FC] py-1">
        <div className="container mx-auto">
          <div className="flex justify-between mx-3">
            <div>Darren Tay - Master Trainer</div>
            <div>Home / Our Faculty / Darren Tay - Master Trainer</div>
          </div>
        </div>
      </section>

      <section id="about-darren" className="mx-3">
        <div className="container flex flex-col items-center mx-auto space-y-10 lg:space-y-0 lg:space-x-10 lg:mt-20 md:flex-row">
          <div className="w-full lg:w-1/2">
            <img
              className="items-center"
              src="/images/darren-tay-wen-jie-2.png"
              alt=""
            />
          </div>
          <div className="w-full lg:w-1/2">
            <p className="text-lg font-bold text-textColor font-primary">
              Darren Tay Wen Jie
            </p>
            <p className="text-lg text-textColor font-text">
              Founder and Master Trainer,
            </p>
            <p className="text-lg text-textColor font-text">
              Public Speaking Academy
            </p>
            <p className="pt-3 text-lg font-bold text-textColor font-primary">
              Education Profile:
            </p>
            <p className="text-lg text-textColor font-text">
              Bachelor of Laws (LL.B.), National University of Singapore,
              Faculty of Law, Class of 2014
            </p>
            <p className="text-lg text-textColor font-text">
              Dean’s Lister Academic Year 2011-2012
            </p>
            <p className="text-lg text-textColor font-text">
              Master of Laws (LL.M.) Specialized in Corp & Finance. National
              University of Singapore, Faculty of Law, Class of 2015
            </p>
            <p className="text-lg text-textColor font-text">
              Called to the Singapore Bar, Year 2016
            </p>
            <p className="text-lg text-textColor font-text">
              Hwa Chong Institution
            </p>
            <p className="text-lg text-textColor font-text">
              Outstanding Student Award 2008
            </p>
            <p className="pt-3 text-lg font-bold text-textColor font-primary">
              Professional Profile:
            </p>
            <p className="text-lg text-textColor font-text">
              First Singaporean World Champion of Public Speaking
            </p>
            <p className="text-lg text-textColor font-text">
              Best-selling author of “Express to Impress”
            </p>
            <p className="text-lg text-textColor font-text">
              9-time District 80 speech contest Champion
            </p>
            <p className="text-lg text-textColor font-text">
              PA Humorous Speech Challenge Cup 2011 Champion
            </p>
            <p className="pt-3 text-lg text-textColor font-text">
              Spoken and Trained more than 250,000 students and working
              professionals (including private training for CEOs, Senior
              Management staff and Executives) on effective communication skills
              useful in the classroom and the boardroom
            </p>
            <p className="pt-3 text-lg text-textColor font-text">
              In 2019, trained the Hong Kong, Education Bureau, Native-English
              Teachers Section on how to design and teach public speaking to
              public schools in Hong Kong (Primary and Secondary level); this is
              part of our Train-the-Trainer/Teacher programme
            </p>
          </div>
        </div>
      </section>
      {/* <!-- Testimonials --> */}
      <section id="about-more">
        <div className="bg-[#f5f5fc] lg:mx-20 rounded-3xl">
          <div className="container mx-auto">
            <div className="py-10 mx-3 lg:py-24">
              <p className="text-lg text-textColor font-text">
                Darren received his Bachelor of Laws (LL.B. Hons) from the
                National University of Singapore, Faculty of Law in 2014 and his
                Master of Laws (LL.M.), with a specialization in Corporate and
                Finance in 2015. He has completed and passed his Part B Bar
                examinations, Singapore Institute of Legal Education and has
                been admitted to the Singapore Bar in 2016.
              </p>
              <p className="pt-3 text-lg text-textColor font-text">
                In his academic journey at NUS, Faculty of Law, Darren excelled
                in his studies, mooting and advocacy competitions, as well as
                co-curricular activities. Darren was conferred the Dean’s List
                award for Academic Year 2011-2012 for emerging top 5% in his
                cohort, excelling in subjects such as Property Law,
                Constitutional Law, Family Law, Evidence Law and Biomedical Law
                and Ethics. Darren went on to pursue his Masters Degree
                (specializing in Corporate and Finance) and did extensive
                research on Contract Law, Company Law, Banking Law, Credit and
                Security and International Investment Law.
              </p>

              <p className="pt-3 text-lg text-textColor font-text">
                Notwithstanding his hectic academic schedule (having to manage
                content-intensive subjects for each semester), Darren actively
                took part in mooting and advocacy, negotiations and Model
                Parliament competitions. In 2013, Darren went all the way to the
                Grand Finals in the Advocacy Cup 2013 (an annual trial advocacy
                competition by Drew and Napier) and argued his case before a
                panel of distinguished judges (CJ Chan Sek Keong, as he then
                was; Mr Hri Kumar, Professor Jeffrey Pinsler). He emerged joint
                2nd runner-up for the competition.
              </p>
              <p className="pt-3 text-lg text-textColor font-text">
                Darren also represented NUS, Faculty of Law in the Singapore
                Model Parliament in 2013 and 2014 and clinched the Outstanding
                Model Parliamentarian Award for two years consecutively (an
                award given to the Top Model Parliamentarian for the entire
                competition).
              </p>
              <p className="pt-3 text-lg text-textColor font-text">
                In 2014, Darren represented Singapore in the International
                Negotiation Competition 2014 (held at Pohang, South Korea) which
                saw top legal negotiators from numerous countries such as the
                United States of America, United Kingdom, Australia, South
                Korea, Japan, Russia among many others. Team Singapore emerged
                top amongst the Southeast Asian teams; second amongst the Asian
                teams; and Top 10 in the World.
              </p>
              <p className="pt-3 text-lg text-textColor font-text">
                On the effective communication front, Darren is a prolific
                public speaker, debater and professional trainer. He founded the
                Public Speaking Academy in 2009 and has led the Academy to scale
                great heights in the school and corporate domains; including the
                successful organisation of the annual National Public Speaking
                Competition since 2010.
              </p>
              <p className="pt-3 text-lg text-textColor font-text">
                As an avid public speaking competitor (at both national and
                international level competitions), Darren has clinched numerous
                accolades and achievements. In 2011, Darren was the champion of
                the People’s Association Humorous Speech Challenge Cup (a
                national humorous speech competition organised by the People’s
                Association which saw competitors representing their respective
                communities in Singapore). In 2009, 2012, 2013, 2014 and 2015,
                2016 Darren won the Toastmasters International District 80
                Speech Championships (in International Speech, Evaluation and
                Table Topics) and stands as the youngest District Champion with
                nine wins in Singapore. Above and beyond the recent
                competitions, Darren has also won several national and
                inter-zonal public speaking and debating championships during
                his Secondary School and Junior College days. Finally, Darren
                emerged as the youngest World Champion of Public Speaking in the
                world at age 27 in 2016 at Washington DC, USA.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="about-more">
        <div className="lg:mx-20">
          <div className="container mx-auto">
            <div className="py-10 mx-3 lg:py-24">
              <div className="flex justify-center pb-10">
                <img
                  src="images/public-speaking-course-for-adults-public-speaking-courses-for-adults-public-speaking-for-adults-1-4-576x1024.png"
                  alt=""
                />
              </div>
              <p className="text-lg text-textColor font-text">
                It is his background as a public speaking and debating
                contestant that motivated Darren to pursue professional training
                to help others to acquire effective communication skills. Till
                date, Darren has spoken and trained more than 250,000 students
                and working professionals (including private training for CEOs,
                Senior Management staff and Executives) on effective
                communication skills useful in the classroom and the boardroom.
                Darren is a highly sought-after keynote speaker, coach and
                trainer (www.darrentay.com). Having travelled and spoken to
                audiences in 20 countries, Darren is an inspiration to many
                youths worldwide that excellence in effective communication can
                be achieved with the right techniques and strategies at a young
                age.
              </p>
              <p className="pt-3 text-lg text-textColor font-text">
                Darren has also partnered with Samsung Singapore as their expert
                adjudicator and authority in their BeFearless Campaign;
                incorporating VR technology into soft skills training in 2017.
                Darren has been using technology since 2009 such as video
                recording systems to complement his unique teaching
                methodologies and 3-tiers of evaluation matrix to enhance and
                optimize the learning experience of students. Darren has also
                partnered with Samsung Singapore and Skillsfuture Singapore to
                incorporate VR technology in training public speaking. Darren
                has also been invited by the HongKong Federation of Student
                Association to train and share his expertise with fellow leaders
                and students in the Summer School Programme; Train the Trainers
                Programme and HK200.
              </p>

              <p className="pt-3 text-lg text-textColor font-text">
                Darren also published his best-selling public speaking guidebook
                “Express to Impress” in 2012 and looks forward to subsequent
                publications in the future.
              </p>
              <div className="flex justify-center py-10">
                <img
                  src="/images/public-speaking-course-for-adults-public-speaking-courses-for-adults-public-speaking-for-adults-3-1024x654.jpg"
                  alt=""
                />
              </div>
              <p className="pt-3 text-lg text-textColor font-text">
                Given his accolades, achievements and professional training
                experiences, Darren has been invited to produce articles on
                print publications (to offer his insights and expert opinions on
                effective communication skills) such as Her World magazine, The
                Straits Times, BusinessInsider, Yahoo Finance and CNN,
                Toastmasters International District 80 Division journals and
                newsletters among others as well as online media and forums.
                Darren has also been invited to appear on multiple national and
                international media platforms, notably CNN, BBC Global, The
                Straits Times, ChannelNews Asia, The Arab Times,
                BusinessInsider, Goalcast, amongst many others.
              </p>
              <p className="pt-3 text-lg text-textColor font-text">
                Darren is also the Singapore Book of Records holder as the
                “First Singaporean World Champion of Public Speaking”.
              </p>
              <div className="flex justify-center py-10">
                <img
                  src="/images/public-speaking-course-for-adults-public-speaking-courses-for-adults-public-speaking-for-adults-4.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
