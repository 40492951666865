import { NavLink } from "react-router-dom";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import { Helmet } from "react-helmet";

export default function TermsConditions() {
  return (
    <>
      <Helmet>
        <title>Terms & Conditions | Public Speaking Academy</title>
        <meta
          name="description"
          content="Find out why we are the preferred choice for effective communication excellence and public speaking course for kids in Singapore. Led by top public speaker, Darren Tay."
        />
      </Helmet>

      <section id="breadcrumb" className="bg-[#F5F5FC] py-1">
        <div className="container mx-auto">
          <div className="flex justify-between">
            <div>Data Protection Policy</div>
            <div>Home / Data Protection Policy</div>
          </div>
        </div>
      </section>

      <section id="latest-blog">
        <div className="container mx-auto">
          <div className="flex pt-5 space-x-10">
            <div className="w-2/3">
              <h1 className="text-3xl font-bold uppercase font-primary text-primary">
                Our Terms & Conditions
              </h1>
              <p className="my-5 text-2xl text-textColor font-text">
                Our policies to ensure a smooth programme and learning
                experience for you!
              </p>
              <div>
                <p className="py-3 font-bold">General Terms & Conditions</p>
                <ol className="px-5 pb-5 list-decimal">
                  <li className="py-2">
                    By submitting the Student Registration Form, the student
                    and/or parents/guardian undertake(s) that the information
                    contained herein is true, accurate, correct and complete.
                  </li>
                  <li className="py-2">
                    The Public Speaking Academy (“PSA”) shall be entitled to
                    refuse registration of a prospective student and/or expel an
                    existing student from the school should the student grossly
                    violates the Code of Conduct or classroom decorum, which
                    include, but is not limited to, grossly disrupting classroom
                    proceedings and/or abusing faculty members. PSA’s Management
                    Committee shall be the sole arbiter to decide whether an act
                    in question falls within the ambit of gross violations.
                  </li>
                  <li className="py-2">
                    Students who would like to take the any relevant assessment,
                    certification, or examinations shall notify and make the
                    necessary arrangements with PSA in advance. PSA shall not be
                    liable for any delays to students resulting from changes,
                    amendments or modifications to PSA’s examination schedule,
                    class timings/venue etc.
                  </li>
                  <li className="py-2">
                    There will be no lessons conducted at all PSA branches on
                    holidays designated by PSA or official public holidays.
                  </li>
                  <li className="py-2">
                    The materials distributed by PSA are for the private
                    learning of the students only, and students and/or
                    parents/guardian shall undertake not to sell, disclose,
                    modify, alter, publish and/or misappropriate any of the
                    materials (in all forms) without the express permission and
                    approval of the PSA Management Committee.
                  </li>
                  <li className="py-2">
                    PSA reserves the right to amend the Terms & Conditions
                    herein by providing a notice period of 3 working days via
                    email or by post, to the designated email address/mailing
                    address on the Student Registration Form. By sending the
                    notification, the student and/or parents/guardian is deemed
                    to have received, read and agreed to the notification.
                    Students who would like to withdraw as a student as a result
                    of the change(s) to the Terms & Conditions may do so
                    immediately within the notice period via our official
                    correspondence. Any failure to inform PSA with regards to an
                    intention to withdraw after the expiry of the notice period
                    will be deemed as acknowledgement and acceptance of the
                    amended Terms & Conditions. For avoidance of doubt, any
                    un-used credits/credit note of a fully/partially-paid up
                    course/module will not be refunded to the student in the
                    event of such withdrawal.
                  </li>
                  <li className="py-2">
                    The official correspondence mode with PSA on all matters,
                    including but not limited to, class scheduling, student
                    management or payment matters, is via email at
                    support@publicspeakingacademy.com.sg /
                    psacademy.sg@gmail.com or our hotline at 6443 4049 /
                    86860753 (within our operating hours).
                  </li>
                  <li className="py-2">
                    Any requests or appeal to depart from the terms and
                    conditions above must be addressed to the PSA Management
                    Committee, and all decisions by the Committee are final and
                    conclusive. Any appeal pertaining to any requests will be
                    assessed on a case-by-case basis by PSA’s Management
                    Committee, and all decisions by the Committee are final and
                    conclusive.
                  </li>
                </ol>

                <p className="pb-5 font-bold">
                  Consent for Personal Data (Collection, Use & Disclosure) and
                  Video Recording
                </p>
                <ol className="px-5 pb-5 list-decimal">
                  <li className="py-2">
                    By submitting this Form, you hereby agree that PSA may
                    collect, use and/or disclose your personal data that you
                    provide in this Form for the purpose of sending you updates
                    and/or managing the administrative aspects about the
                    courses, lessons, assessments, examinations or any other
                    programmes conducted, operated and/or managed by PSA, and to
                    allow PSA to provide the appropriate services to you. The
                    personal data collected may also include video recording of
                    the students’ in-class presentation (if they do not have
                    their own mobile phones or recording devices) during the
                    conduct of the class by PSA training staff for the purpose
                    of updating you on the progress of the student/your child.
                    The videos recorded will not be used for any other
                    purpose(s) unless fresh consent is obtained from you.
                  </li>
                  <li className="py-2">
                    If you are providing someone else’s personal data or
                    submitting this Form on behalf of someone else, you hereby
                    declare that you have obtained consent from the named
                    individual(s) in this Form, for the collection, use and
                    disclosure of his/her personal data by you to PSA. For
                    students below the age of 19 years, you hereby confirm that
                    you are providing valid consent on behalf of the student as
                    his/her parent(s)/guardian(s).
                  </li>
                  <li className="py-2">
                    PSA respects the privacy of individuals and recognizes the
                    importance of the personal data you have entrusted to us and
                    believes that it is our responsibility to properly manage,
                    protect, and process your personal data. We will collect,
                    use and/or disclose your personal data in accordance with
                    the Personal Data Protection Act 2012. If you would like to
                    find out more about our Personal Data Protection Policy,
                    please request to view our Data Protection & Privacy Notice.
                    By signifying your acknowledgement and consent, you are
                    deemed to have read, understood and consented to our Data
                    Protection & Privacy Notice.
                  </li>
                  <li className="py-2">
                    Should you wish at any time to withdraw your consent for the
                    collection and/or use of your personal data after submitting
                    this Form, please contact us at
                    contact@publicspeakingacademy.com.sg
                  </li>
                </ol>

                <p className="pb-5 font-bold">Pricing & Payment</p>
                <p className="pb-5 font-bold">Applicable to all Programmes</p>
                <ol className="px-5 pb-5 list-decimal">
                  <li className="py-2">
                    For any late payment of any sums due, PSA reserves the right
                    to impose a late payment charge of 4% (of the payment due)
                    per week of delay. Any request for waiver of the late
                    payment charges shall be evaluated on a case-by-case basis
                    by the Management Committee.
                  </li>
                  <li className="py-2">
                    No refunds will be made to any student and/or
                    parents/guardians under any circumstances whatsoever for
                    course(s)/programme(s) and/or examination fees paid.
                  </li>
                  <li className="py-2">
                    <b>Force Majeure: </b>In the event PSA is delayed or unable
                    to perform its obligations (i.e. conduct of the course,
                    lesson, or programme on the stipulated date, time, medium,
                    and/or venue) due to acts of God, wars, strikes, equipment
                    failure, or any other circumstances beyond PSA’s control
                    (such as, but not limited to, a pandemic event (e.g.
                    COVID-19) and corresponding legislation, measures,
                    guidelines, recommendations, or any form of restriction
                    related to such pandemic (e.g. lockdown, circuit-breaker))
                    which affects the conduct of the course, lesson, or
                    programme (“a Force Majeure event”), PSA shall not be deemed
                    to be in breach of its obligations or otherwise be liable to
                    the participant/parent/guardian for any damages resulting
                    from any delay or non-performance of its obligations. In
                    such a Force Majeure event, PSA reserves the right to
                    perform its obligations on an alternative date, time, venue,
                    mode, and/or arrangements (such as, but not limited to,
                    online platforms), in satisfaction of its obligations as
                    agreed between PSA and the participant/parent/guardian.
                  </li>
                  <li className="py-2">
                    <b>Skillsfuture/PayPal (if applicable): </b>Participants
                    using Skillsfuture and/or Paypal to make partial payment
                    undertake to abide by the relevant organisations’ terms and
                    conditions for usage. Any failure to adhere to the
                    submissions, payment obligations, and/or payment deadline
                    leading to the non-disbursement of funds from Skillsfuture
                    and/or PayPal to PSA for the part payment due will render
                    the participant/parent/guardian personally responsible and
                    liable to make any pending and due partial payment to PSA.
                    Participants also undertake to make the full payment for the
                    course registered should Skillsfuture and/or PayPal, for any
                    reason, disapproves, rejects, or disallows any disbursements
                    of the participant’s Skilllsfuture credit and/or PayPal
                    funds, for any payments due to PSA.
                  </li>
                </ol>

                <p className="pb-5 italic font-bold">
                  Speech Excellence Programme ("SEP")
                </p>
                <ol className="px-5 pb-5 list-decimal">
                  <li className="py-2">
                    The minimum commitment for the SEP is 3 months (i.e. 3
                    cycles of 4 lessons per cycle). A deposit of SGD 100.00 per
                    student is collected with the relevant monthly fee for the
                    1st month payment, where payment for the SEP is made on a
                    monthly basis.
                  </li>
                  <li className="py-2">
                    The deposit collected shall be forfeited if the student
                    withdraws or ceases to continue as a student with PSA,
                    without meeting the minimum commitment of 3 months. Where
                    the minimum commitment of 3 months is met, the deposit
                    collected will be used to offset the last cycle of fees
                    payable to PSA. Payment is collected on the first day of
                    each month and/or the start of the 1st lesson of each new
                    cycle. If payment is made late on two or more occasions,
                    there will be late payment charges of 4% (of the payment
                    due) per week. Any request for waiver of the late payment
                    charges shall be evaluated on a case-by-case basis by the
                    Management Committee.
                  </li>
                  <li className="py-2">
                    Under our Pro-Rate Arrangement, each student may only miss a
                    maximum of one lesson per month, with the relevant course
                    fee pro-rated for the lesson missed in the form of course
                    credit/credit note. This is subject to extenuating
                    circumstances, documentary proof of the
                    medical/non-medical/travel reasons for missing the lesson
                    and the approval of the Management Committee. For the
                    avoidance of doubt, each student may miss only a maximum of
                    one lesson per month; and cannot be carried forward to
                    subsequent months. The course credit/credit note may be used
                    to offset any payments due to PSA. There will be no
                    replacement (e.g. by attending another class), alternative,
                    or make-up lesson/session (whether online or otherwise) for
                    the pro-rated lesson.
                  </li>
                  <li className="py-2">
                    For any subsequent lessons missed beyond the{" "}
                    <b>Pro Rate Arrangement,</b> students may request a link for
                    an online video sharing the techniques for the lesson
                    missed. The sending of the link to the video will be
                    arranged separately, based on the lessons missed. For
                    avoidance of doubt, there will be no further pro-rating
                    and/or refunds of any subsequent lessons missed beyond the
                    Pro Rate Arrangement. There will also not be any physical
                    make up session arranged for the lessons missed
                  </li>
                  <li className="py-2">
                    <b>Withdrawal from Programme:</b> Students who wish to
                    discontinue the programme (whether after completion of a
                    paid term/fees or otherwise) must inform PSA via our
                    official correspondence mode. Students who do not inform PSA
                    of such withdrawal will be regarded as expressing an intent
                    to continue with the programme (e.g, will be invoiced for
                    subsequent fees/payment, slot in the class is retained).
                  </li>
                </ol>

                <p className="pb-5 italic font-bold">
                  World Champion Certification Programme ("WCCP")
                </p>
                <ol className="px-5 pb-5 list-decimal">
                  <li className="py-2">
                    The adult learner is required to meet the minimum commitment
                    of the full duration of the WCCP i.e. a total of 12 lessons,
                    of 1.5 hours each. A deposit of SGD 100.00 is collected with
                    the relevant monthly fee for the 1st month payment, where
                    payment for the WCCP is made on a monthly basis.
                  </li>
                  <li className="py-2">
                    The deposit collected shall be forfeited if the adult
                    learner fails to meet the minimum commitment of 12 lessons.
                    Where the minimum commitment of 12 lessons is met, the
                    deposit collected will be used to offset the last cycle of
                    fees payable to PSA. For example, if the adult learner
                    enrolls at the start of July 2020, but subsequently intends
                    to withdraw before completing the 12 lessons, the deposit
                    collected will be forfeited. However, if the adult learner
                    continues and makes payment for the last cycle of lessons,
                    the deposit collected will be used to offset the last cycle
                    of payment (for 4 lessons) (as the minimum commitment is
                    met).
                  </li>
                  <li className="py-2">
                    Payment is collected on the 1st lesson of each new cycle
                    i.e. on or before the 1st, 5th, and 9th lesson, if made
                    monthly. Any full payment of the 12 lessons must be made on
                    or before the 1st lesson of all three cycles.
                  </li>
                </ol>

                <p className="pb-5 italic font-bold">
                  S'Peak Performance Programme (English) ("SPP")
                </p>
                <ol className="px-5 pb-5 list-decimal">
                  <li className="py-2">
                    Payment must be made in full to reserve a slot. For
                    completeness, any deposit paid (if any) will not be refunded
                    even if the participant is unable to attend the course
                    registered for (whether in part or in full), for any reason.
                  </li>
                  <li className="py-2">
                    <b>Replacement/Missing of Course:</b> There will be no
                    replacement (e.g. by attending another course run, term,
                    class, programme, or session) or refund of any fees paid
                    should the student miss or is unable to attend the course,
                    whether the student missed or is unable to attend the course
                    in part or in full. Any request or appeal for replacement or
                    refund will be assessed on a case-by-case basis by the PSA
                    Management Committee, subject to the provision of detailed
                    reasons and valid supporting documents (e.g. medical
                    certificate, record, or hospital discharge summary).
                  </li>
                </ol>
              </div>
            </div>

            <div className="w-1/3 px-5 pt-5 mb-10 border border-gray-400 border-dashed rounded-lg">
              <Sidebar />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
