import Header from "../components/Header";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import { Helmet } from "react-helmet";

export default function SchoolEnrichmentProgrammes() {
  return (
    <>
      <Helmet>
        <title>
          Public Speaking Training for Schools (Workshop) | Public Speaking
          Academy
        </title>
        <meta
          name="description"
          content="Find out why we are the preferred choice for effective communication excellence and public speaking course for kids in Singapore. Led by top public speaker, Darren Tay."
        />
      </Helmet>

      <section id="breadcrumb" className="bg-[#F5F5FC] py-1">
        <div className="container mx-auto">
          <div className="flex flex-row justify-between mx-3">
            <div>SCHOOL / ENRICHMENT PROGRAM</div>
            <div>
              Home / Corporate / School Training / School/Enrichment Programmes
            </div>
          </div>
        </div>
      </section>

      <section id="block-1" className="mx-3">
        <div class="container mx-auto py-20">
          <div class="flex flex-col lg:flex-row pt-5 space-x-10">
            <div class="w-full lg:w-2/3">
              <h1 class="font-primary text-2xl font-bold text-primary uppercase">
                Public Speaking Training for Schools & Educational Institutes
                (all levels)
              </h1>
              <h2 class="font-primary text-xl font-bold text-primary uppercase py-5">
                Customised & Tailored Training for Your Students to Grow as
                Confident, Competent, and Creative Communicators
              </h2>
              <div>
                <img
                  className="block mx-auto"
                  src="/images/public-speaking-course-for-adults-public-speaking-courses-for-adults-public-speaking-for-adults-1-4.png"
                  alt=""
                />
                <div className="p-5 my-5 bg-orange-200 rounded-2xl ">
                  <svg
                    width="48"
                    height="34"
                    viewBox="0 0 48 34"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M44.0002 33.6666H34.0002L27.3335 20.3333V0.333313H47.3335V20.3333H37.3335L44.0002 33.6666ZM17.3335 33.6666H7.33349L0.666824 20.3333V0.333313H20.6668V20.3333H10.6668L17.3335 33.6666Z"
                      fill="#F17732"
                    />
                  </svg>
                  <h3 class="font-text font-bold text-2xl text-primary py-5">
                    Right now, I would pay $100,000 for 10 percent of the future
                    earnings of any of you, so if you’re interested, see me
                    after class. Now, you can improve your value by 50 percent
                    just by learning communication skills--public speaking. If
                    that’s the case, see me after class and I’ll pay you
                    $150,000.
                  </h3>
                  <p className="mb-10 text-lg text-right">
                    Warren Buffett, 2009
                  </p>
                </div>

                <p class="py-2 font-text text-lg">
                  We believe you are as good as what you communicate. If you are
                  looking for a training programme for your students, teachers,
                  or school to help nurture a confident, competent, and coherent
                  voice, we have got you covered. At Public Speaking Academy, we
                  design and deliver customised school training to introduce
                  your learners to public speaking and communication skills.
                  Whether it is to develop your students’ speaking confidence or
                  to focus on their persuasive precision, we deliver dedicated
                  and relevant communication skills, building on our expertise
                  and experience.
                </p>
                <p class="py-2 font-text text-lg">
                  Public speaking, communication or even persuasion skills are
                  no longer ‘just’ trivial “soft” skills. We want to guide your
                  students through these coveted skill-set in the modern home,
                  workplace, and world.
                </p>

                <h3 class="py-5 font-text text-2xl font-bold italic">
                  So, why the Public Speaking Academy?
                </h3>
                <p class="py-2 font-text text-lg">
                  The process of nurturing public speaking confidence can be a
                  daunting path. With Public Speaking Academy, we will bring you
                  expert and experienced communication training, driven by our
                  passion to help you present your students’ voice to a wider
                  audience. From 2009, and building on our Master Trainer’s
                  experience and methodology, we have been conducting public
                  speaking training for various school-based clients (such as
                  Primary/Secondary schools, Institutes of Higher Learning,
                  etc.) in their goal of nurturing impeccable communicators.
                </p>
                <img
                  className="my-10"
                  src="/images/public-speaking-course-for-adults-public-speaking-courses-for-adults-public-speaking-for-adults-3-3.jpg"
                  alt=""
                />

                <p className="text-lg font-text">
                  With the Public Speaking Academy as your choice for a
                  school-based training provider, you will get:
                </p>
                <ul className="p-5 list-disc list-outside">
                  <li className="text-lg font-text">
                    <b>World Champion Experience:</b> Our programmes are helmed
                    by our quality and professional training faculty members,
                    each in high demand for expertise, accolades, and experience
                    in public speaking. Our training team is led by our Master
                    Trainer/Founder, Darren Tay, the World Champion of Public
                    Speaking in 2016, leveraging his experience in
                    speaking/training more than 250,000 individuals in effective
                    communication skills to utilise in the classroom, future
                    workplace, and the world.
                  </li>
                  <li className="text-lg font-text">
                    <b>Extensive Experience:</b> Our extensive experience in
                    public speaking stretches from 2009, growing to a total of 3
                    branches around Singapore. We have served SMEs, corporate
                    organisations, training companies, schools, and even
                    start-up incubators in customising public
                    speaking/communication solutions for their unique needs. Our
                    mainstay programme has also served students of various age
                    groups (from primary, secondary, international grades, young
                    teen/adults).
                  </li>
                  <li className="text-lg font-text">
                    <b>Customised Experience:</b> We draw from our extensive
                    syllabus and knowledge base to design a customised learning
                    experience that best fits your goals and budget – be it a
                    keynote, a masterclass, or a full public speaking
                    workshop/boot camp. Whether you are looking at developing
                    general public speaking confidence to specialised
                    communication topics for your students (e.g., interview,
                    speech confidence, impromptu), we will work closely with you
                    to design a programme tailored to your students’ unique
                    learning needs.
                  </li>
                </ul>

                <h3 class="py-5 font-text text-2xl font-bold italic">
                  What Will My Students & School Benefit From?
                </h3>
                <p className="text-lg font-text">
                  While every school/student carries a unique need, we are
                  versatile enough to reach into our knowledge and experience
                  bank to fill a gap you may have. We adopt a reasoned,
                  researched, and relevant approach in designing our programmes
                  that straddle a careful balance between (i) engaging theory
                  sharing (e.g., presentations on technique), and (ii) evaluated
                  practice sessions (e.g., practice presentations with
                  feedback). These are some examples of the programmes we have
                  conducted before:
                </p>
                <ul className="p-5 list-disc list-outside">
                  <li className="text-lg font-text">
                    <b>Classroom Confidence:</b> We have designed programmes
                    that focus on building general speech and presentation
                    confidence before an audience - these are public speaking
                    techniques directly applicable to their classroom (e.g.,
                    in-class presentation skills, hosting, interpersonal
                    communication)
                  </li>
                  <li className="text-lg font-text">
                    <b>Leadership Communication Confidence:</b> Our young
                    communicators and students are the future leaders of this
                    world. We have designed school-based training with a focus
                    on leadership communication skills from the perspective of
                    public speaking (e.g., student ambassadors, persuasive
                    speaking, inspirational speaking).
                  </li>
                  <li className="text-lg font-text">
                    <b>Future-Ready Confidence:</b> Our school-based training is
                    not just designed for the present; they prepare the students
                    for the future. We have developed and delivered public
                    speaking programmes that nurtures versatile communicators
                    (e.g., journalism, online delivery, persuasive speaking,
                    argumentation)
                  </li>
                </ul>

                <p class="py-2 font-text text-lg">
                  We have covered a wide variety of topics, which include:
                </p>
                <p className="pt-5 pb-5 text-3xl font-bold text-center">
                  Topics Covered:
                </p>
                <img className="my-5" src="/images/topics-covered.jpg" alt="" />
                <p className="pt-5 pb-5 text-2xl italic font-bold font-primary">
                  Alright, I am in! What Now?
                </p>
                <p className="text-lg font-text">
                  We are excited to begin this journey with you to achieve
                  public speaking success! First, we would love to know more
                  about you and your schools/students’ communication needs -
                  feel free to reach out to us through our Query Form to start
                  the conversation and curate your public speaking training.
                </p>
                <p className="py-3 text-lg font-text">
                  We will be there with you as we design the best-fit programme
                  to develop your students into highly effective public speakers
                  in the classroom, empowered with charisma, influence, and
                  impact!
                </p>
                <p className="py-3 text-lg font-bold font-text">
                  In reaching out to us, it would be helpful to share these
                  preliminary information to help us understand how best to
                  start:
                </p>

                <ul className="p-5 list-disc list-outside">
                  <li className="text-lg font-text">
                    Total number of participants
                  </li>
                  <li className="text-lg font-text">
                    Total duration (estimated)
                  </li>
                  <li className="text-lg font-text">
                    Nature of programme (e.g., 1-day, masterclasses, regular
                    sessions)
                  </li>
                  <li className="text-lg font-text">
                    Total number of trainers required
                  </li>
                  <li className="text-lg font-text">
                    Total duration of training (including time distribution,
                    e.g., 18 hours of instruction, over 2 days or 4 days)
                  </li>
                  <li className="text-lg font-text">
                    Summary of the learning objectives
                  </li>
                  <li className="text-lg font-text">
                    Preferred training venue (at one of our learning centres or
                    on-site)
                  </li>
                  <li className="text-lg font-text">
                    Estimated budget for the assignment
                  </li>
                </ul>
                <p className="py-3 text-lg font-text">
                  We look forward to collaborating with you in designing a
                  public speaking school-based training suited to your students’
                  needs!
                </p>
              </div>
            </div>
            <div className="hidden lg:inline w-1/3 px-5 pt-5 mb-10 border border-dashed rounded-lg">
              <Sidebar />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
