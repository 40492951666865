import Footer from "../components/Footer";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

export default function About() {
  return (
    <>
      <Helmet>
        <title>
          Public Speaking Course by Top Public Speaking Trainer in Singapore
        </title>
        <meta
          name="description"
          content="Find out why we are the preferred choice for effective communication excellence and public speaking course in Singapore. Led by top public speaker, Darren Tay."
        />
      </Helmet>
      <section id="breadcrumb" class="bg-[#F5F5FC] py-1">
        <div class="container mx-auto">
          <div class="flex justify-between mx-3">
            <div>ABOUT US</div>
            <div>Home / About / Mission & Vision</div>
          </div>
        </div>
      </section>
      {/* <!-- About PSA --> */}
      <section id="about-psa" className="mx-3">
        <div class="my-5">
          <div class="container mx-auto">
            <h2 class="text-primary font-primary font-bold uppercase text-3xl md:text-4xl py-4">
              PUBLIC SPEAKING ACADEMY
            </h2>
            <hr class="w-24 h-1 bg-[#8598AF] rounded border-0" />
            <p class="font-text text-xl md:text-2xl  text-textColor my-14 md:leading-9">
              The Public Speaking Academy aims to expand your public speaking
              confidence from your two feet to the entire world. Led by our
              qualified and diverse team of public speaking trainers and
              talents, we are passionate about imparting quality and effective
              communication and public speaking skills to you. Through this
              commitment, the Public Speaking Academy has continued to be a
              rapidly growing influence in the local and regional public
              speaking sphere, both in the personal and professional training
              areas, from its inception in 2009.
            </p>
            <div class="flex flex-col md:flex-row space-y-3 md:space-y-0 md:space-x-3">
              <img
                class="md:w-1/4 w-full h-auto rounded-2xl border-r-4 border-b-4 border-[#FFE4D5]"
                src="/images/About-Public-Speaking-Competition-Singapore-1.jpg"
                alt=""
              />
              <img
                class="md:w-1/4 w-full h-auto rounded-2xl border-r-4 border-b-4 border-[#FFE4D5]"
                src="/images/About-Public-Speaking-Competition-Singapore-2.jpg"
                alt=""
              />
              <img
                class="md:w-1/4 w-full h-auto rounded-2xl border-r-4 border-b-4 border-[#FFE4D5]"
                src="/images/About-Public-Speaking-Competition-Singapore-4.jpg"
                alt=""
              />
              <img
                class="md:w-1/4 w-full h-auto rounded-2xl border-r-4 border-b-4 border-[#FFE4D5]"
                src="/images/About-Public-Speaking-Competition-Singapore-5.jpg"
                alt=""
              />
            </div>
            <p class="font-text text-xl md:text-2xl text-textColor my-14 md:leading-9">
              As part of our public speaking courses line-up, the Public
              Speaking Academy offers its outstanding World Champion public
              speaking courses, ranging from weekly public speaking classes for
              children and adults, to intensive boot camps / masterclasses to
              inculcate public speaking skills/techniques. We are honoured to
              receive the valuable support of our clients, corporate agencies,
              education institutions in Singapore/worldwide, and the involvement
              of our professional public speaking trainers in energising the
              public speaking culture in Singapore into a more vibrant one.
              Together, we are driven by our shared Vision and Mission:
            </p>
            <div
              class="relative h-0 overflow-hidden max-w-full w-full"
              style={{ paddingBottom: "56.25%" }}
            >
              <iframe
                src="https://www.youtube.com/embed/bgdSktSV-So"
                frameborder="0"
                allowfullscreen
                class="absolute top-0 left-0 w-full h-full"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Mission Vision Section --> */}
      <section id="mission-vision" className="mx-3">
        <div class="bg-[#f5f5f5] lg:mx-20 mt-20 rounded-3xl">
          <div class="container mx-auto">
            <div class="pt-14">
              <div class="flex flex-col md:flex-row space-y-10 md:space-y-0 md:space-x-5 mx-2">
                <div class="w-full md:w-1/2">
                  <div class="border-2 border-dashed border-gray-400 rounded-2xl p-5">
                    <div class="-mt-8">
                      <p class="bg-white border-solid border inline border-gray-300 font-primary text-xl py-1 px-2 rounded-lg font-bold text-textColor">
                        Our Vision
                      </p>
                    </div>
                    <div class="font-bold font-text text-2xl py-6 text-textColor">
                      To be a premium hub for communication success in the
                      region.
                    </div>
                  </div>
                </div>
                <div class="w-full md:w-1/2">
                  <div class="border-2 border-dashed border-gray-400 rounded-2xl p-5">
                    <div class="-mt-8">
                      <p class="bg-white border-solid border inline border-gray-300 font-primary text-xl py-1 px-2 rounded-lg font-bold text-textColor">
                        OUR Mission
                      </p>
                    </div>
                    <div class="font-bold font-text text-2xl py-6 text-textColor">
                      Accentuating your confidence to express and impress, in
                      bulding a vibrant public speaking/communication culture.
                    </div>
                  </div>
                </div>
              </div>
              <p class="font-text text-xl md:text-2xl text-textColor my-14 leading-9 mx-3">
                With a clear and focused Mission and well articulated Vision,
                the Public Speaking Academy is poised to achieve accelerated
                training success.
              </p>
              <h3 class="font-primary text-2xl text-textColor my-5 leading-9 text-center">
                We aim to help our learners with:
              </h3>
            </div>
            <div class="flex flex-col md:flex-row my-14 space-y-5 md:space-y-0 md:space-x-5">
              <div class="w-full md:w-1/5">
                <span class="material-symbols-rounded grid place-items-center py-5 text-5xl">
                  {" "}
                  thumb_up{" "}
                </span>
                <h2 class="font-text text-textColor font-bold text-2xl py-2 text-center">
                  Confidence
                </h2>
                <p class="font-text text-textColor text-lg text-center">
                  Accentuating our students’ confidence to deliver powerful
                  presentations and speeches effectively and effortlessly
                </p>
              </div>
              <div class="w-full md:w-1/5">
                <span class="material-symbols-rounded grid place-items-center py-5 text-5xl">
                  {" "}
                  monitoring{" "}
                </span>
                <h2 class="font-text text-textColor font-bold text-2xl text-center py-2">
                  Growth
                </h2>
                <p class="font-text text-textColor text-lg text-center">
                  Accelerating our students’ growth in their capacities and
                  capabilities to express and impress competently at home, work,
                  and life
                </p>
              </div>
              <div class="w-full md:w-1/5">
                <span class="material-symbols-rounded grid place-items-center py-5 text-5xl">
                  {" "}
                  diversity_2{" "}
                </span>
                <h2 class="font-text text-textColor font-bold text-2xl text-center py-2">
                  Communication
                </h2>
                <p class="font-text text-textColor text-lg text-center">
                  Accumulating effective communication skills and public
                  speaking techniques to expand our students’ repertoire of
                  talents
                </p>
              </div>
              <div class="w-full md:w-1/5">
                <span class="material-symbols-rounded grid place-items-center py-5 text-5xl">
                  {" "}
                  workspace_premium{" "}
                </span>
                <h2 class="font-text text-textColor font-bold text-2xl text-center py-2">
                  Success
                </h2>
                <p class="font-text text-textColor text-lg text-center">
                  Advancing our students’ prospects, either in school or at a
                  workplace, with relevant and timeless communication skills
                </p>
              </div>
              <div class="w-full md:w-1/5">
                <span class="material-symbols-rounded grid place-items-center py-5 text-5xl">
                  {" "}
                  mood{" "}
                </span>
                <h2 class="font-text text-textColor font-bold text-2xl text-center py-2">
                  Charisma
                </h2>
                <p class="font-text text-textColor text-lg text-center">
                  Amplifying our students’ communications potential with a
                  platform to practise and shine in the spotlight as an
                  influential speaker
                </p>
              </div>
            </div>
            <p class="font-text text-xl md:text-2xl text-textColor text-center my-5 md:leading-9">
              We achieve these goals with a keen focus on our public
              speaking/communications training team, in tailoring the techniques
              covered to your context. With support from the leading partners in
              the public speaking industry, we continue to be a premium hub for
              public speaking and communication success in Singapore and the
              region.
            </p>
          </div>
          <div class="pb-20"></div>
        </div>
      </section>
      {/* <!-- Core Program --> */}
      <section id="core-program" class="my-28 mx-3">
        <div class="">
          <div class="container mx-auto">
            <h2 class="text-primary font-primary font-bold uppercase text-2xl md:text-3xl text-center">
              Public Speaking Core programmes – Our Scope of Communications
              Expertise
            </h2>
            <p class="font-text text-xl md:text-2xl text-textColor text-center my-5 md:leading-9">
              The Public Speaking Academy provides leading public speaking
              programmes, designed by our professional training faculty and
              helmed by our Master Trainer & Principal, World Champion of Public
              Speaking, Darren Tay. These curated training courses are tailored
              to meet the unique educational goals of our clients, and cover
              specific skill-sets (e.g., body language, interviews). They
              include:
            </p>
            <div class="flex flex-col lg:flex-row space-y-10 lg:space-x-10 lg:space-y-0">
              <div class="w-full lg:w-1/5">
                <img src="/images/block-1.png" alt="" />
                <h2 class="font-text text-textColor font-bold text-2xl py-2 text-center">
                  Speech Execellence Programme
                </h2>
                <p class="font-text text-textColor text-lg text-center">
                  Weekly public speaking class for kids ages 9 to 18 years old
                  (designed with a 12-month syllabus)
                </p>
                <p class="text-center pt-5">
                  <NavLink
                    to="/group-classes-students-age-7-18"
                    className="px-3 py-1 text-lg text-white rounded-md bg-btnColor font-primary"
                  >
                    View More
                  </NavLink>
                </p>
              </div>
              <div class="w-full lg:w-1/5">
                <img src="/images/block-2.png" alt="" />
                <h2 class="font-text text-textColor font-bold text-2xl text-center py-2">
                  World Champion Certification Programme
                </h2>
                <p class="font-text text-textColor text-lg text-center">
                  Weekly classes (total of 9-week programme) for adults, as a
                  deep dive into public speaking and communication
                </p>
                <p class="text-center pt-5">
                  <NavLink
                    to="/courses-and-programs/group-classes-for-adults"
                    className="px-3 py-1 text-lg text-white rounded-md bg-btnColor font-primary"
                  >
                    View More
                  </NavLink>
                </p>
              </div>
              <div class="w-full lg:w-1/5">
                <img src="/images/block-3.png" alt="" />
                <h2 class="font-text text-textColor font-bold text-2xl text-center py-2">
                  S’Peak Performance Programme
                </h2>
                <p class="font-text text-textColor text-lg text-center">
                  Intensive 2-day public speaking training programme for
                  children or adults (separate classes), tailored to each group
                </p>
                <p class="text-center pt-5">
                  <NavLink
                    to="/courses-and-programs/intensive-holiday-ad-hoc-public-speaking-programs"
                    className="px-3 py-1 text-lg text-white rounded-md bg-btnColor font-primary"
                  >
                    View More
                  </NavLink>
                </p>
              </div>
              <div class="w-full lg:w-1/5">
                <img src="/images/block-5.png" alt="" />
                <h2 class="font-text text-textColor font-bold text-2xl text-center py-2">
                  Premium One-to-One Training
                </h2>
                <p class="font-text text-textColor text-lg text-center">
                  For the personal touch, design a learning programme / goal
                  that is relevant to you, with the guidance of our expert
                  in-house coaches
                </p>
                <p class="text-center pt-5">
                  <NavLink
                    to="/courses-and-programs/premium-1-to-1-public-speaking-coaching"
                    className="px-3 py-1 text-lg text-white rounded-md bg-btnColor font-primary"
                  >
                    View More
                  </NavLink>
                </p>
              </div>
              <div class="w-full lg:w-1/5">
                <img src="/images/block-4.png" alt="" />
                <h2 class="font-text text-textColor font-bold text-2xl text-center py-2">
                  Customisable Corporate Workshop
                </h2>
                <p class="font-text text-textColor text-lg text-center">
                  Customise a highly relevant & cost-effective public speaking,
                  communication skills, presentation skills training program for
                  your organization’s staff
                </p>
                <p class="text-center pt-5">
                  <NavLink
                    to="/corporate-public-speaking-training-workshop"
                    className="px-3 py-1 text-lg text-white rounded-md bg-btnColor font-primary"
                  >
                    View More
                  </NavLink>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Mission Vision Section --> */}
      <section id="mission-vision">
        <div class="bg-[#f5f5f5] lg:mx-20 mt-20 rounded-3xl px-3">
          <div class="container mx-auto py-10">
            <h2 class="text-primary font-primary font-bold uppercase text-3xl py-4">
              OUR PHILOSOPHY
            </h2>
            <hr class="w-24 h-1 bg-[#8598AF] rounded border-0" />
            <p class="font-text text-2xl text-textColor my-5 leading-9">
              With the philosophy that teaming takes place in systematic phases,
              the Public Speaking Academy is able to customise training
              programmes effectively based on the clients’ needs.
            </p>
            <div class="flex flex-col lg:flex-row lg:space-x-10 lg:space-y-0 space-y-5 items-center justify-center">
              <div class="w-[300px] h-[300px] rounded-full grid place-items-center bg-[#006B99]">
                <h2
                  class="text-3xl font-primary text-white font-bold p-3 chtext"
                  data-hover="Discovering the Model of SUCCESS"
                >
                  PHASE 1
                </h2>
              </div>
              <div class="w-[300px] h-[300px] rounded-full grid place-items-center bg-[#006B99]">
                <h2
                  class="text-3xl font-primary text-white font-bold p-3 chtext"
                  data-hover="Understanding
              the Model of
              SUCCESS
              "
                >
                  PHASE 2
                </h2>
              </div>
              <div class="w-[300px] h-[300px] rounded-full grid place-items-center bg-[#006B99]">
                <h2
                  class="text-3xl font-primary text-white font-bold p-3 chtext"
                  data-hover="Acquiring the Model of SUCCESS"
                >
                  PHASE 3
                </h2>
              </div>
              <div class="w-[300px] h-[300px] rounded-full grid place-items-center bg-[#006B99]">
                <h2
                  class="text-3xl font-primary text-white font-bold p-3 chtext"
                  data-hover="Mastering the Model of SUCCESS"
                >
                  PHASE 4
                </h2>
              </div>
            </div>
            <p class="font-text text-2xl text-textColor py-5 leading-9 mx-3">
              With the philosophy that teaming takes place in systematic phases,
              the Public Speaking Academy is able to customise training
              programmes effectively based on the clients’ needs.
            </p>
            <div className="flex justify-center">
              <img src="/images/abt-2-1.png" alt="" />
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Aims and Objectives --> */}
      <section id="objective" className="mx-3">
        <div class="container mx-auto my-14">
          <h2 class="text-primary font-primary font-bold uppercase text-center text-3xl py-4">
            Our Public Speaking Training Aims & Objectives
          </h2>
          <hr class="mx-auto w-24 h-1 bg-[#8598AF] rounded border-0" />
          <p class="font-text text-2xl text-textColor pt-14 leading-9">
            To provide a <strong>conducive learning environment</strong> for
            students to build up and enhance their self-confidence when speaking
            before a group (large group or otherwise).
          </p>
          <p class="font-text text-2xl text-textColor py-2 leading-9">
            To train students to be proficient in using{" "}
            <strong>non-verbal communication skills</strong> (such as body
            language, vocal variety, pausing with power, stage presence, etc)
            effectively.
          </p>
          <p class="font-text text-2xl text-textColor py-2 leading-9">
            To train students to employ <strong>linguistic tools</strong> in
            various aspects of speechcraft such as incorporating the power of
            three, literary devices, pronunciation & enunciation as well as
            proper choice of words.
          </p>
          <p class="font-text text-2xl text-textColor py-2 leading-9">
            To introduce speechcraft techniques in building{" "}
            <strong>versatility</strong> for different communication settings,
            e.g., speeches for the special occasions (i.e. emceeing), speeches
            to inform (i.e. to present a fact-finding report to a committee
            etc), speeches to persuade and inspire
          </p>
          <p class="font-text text-2xl text-textColor py-2 leading-9">
            To train students to be proficient in tackling{" "}
            <strong>impromptu speech topics</strong> and to think fast on their
            feet.
          </p>
          <p class="font-text text-2xl text-textColor py-2 leading-9">
            <strong>Overall,</strong> to help you become that confident
            communicator whom can take any stage by storm, deliver your ideas
            effectively, and move your audience into action through your message
          </p>
        </div>
      </section>

      <Footer />
    </>
  );
}
