import Header from "../components/Header";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";

const IntensiveHolidayAdhoc = () => {
  return (
    <>
      <Helmet>
        <title>
          Intensive, Holiday and Ad-hoc Programs | Public Speaking Academy
        </title>
        <meta
          name="description"
          content="The 2-day intensive public speaking programmes, with syllabi tailored for both children and adults, are helmed by our very own World Champion of Public Speaking, Darren Tay. Learn effective communication skills to speak with flair and confidence!"
        />
      </Helmet>

      <section id="breadcrumb" className="bg-[#F5F5FC] py-1">
        <div className="container mx-auto">
          <div className="flex justify-between mx-3">
            <div>Intensive, Holiday and Ad-hoc Programs</div>
            <div>
              Home / Courses and Programs / Intensive, Holiday and Ad-hoc
              Programs
            </div>
          </div>
        </div>
      </section>

      <section id="block-1" className="mx-3">
        <div class="container mx-auto py-20">
          <div class="flex flex-col lg:flex-row pt-5 space-y-10 lg:space-y-0 lg:space-x-5">
            <div class="w-full lg:w-2/3">
              <div>
                <h1 class="font-primary text-3xl font-bold text-primary uppercase">
                  Holiday / Intensive Public-Speaking Masterclasses (for
                  Children & Adults)
                </h1>
                <div>
                  <p class="pt-5 pb-2 font-text text-lg">
                    Are you looking for an intensive, school holiday public
                    speaking masterclass programme? Whether you are a student or
                    an adult / working professional – we present to you
                    Singapore’s only World Champion S’Peak Performance
                    Masterclass for both students and adults!
                  </p>
                  <p class="pt-2 pb-5 font-text text-lg">
                    The 2-day intensive public speaking programme, with syllabi
                    tailored for both children and adults, is helmed by our very
                    own World Champion of Public Speaking and Master Trainer,
                    Darren Tay. Learn effective communication skills from the
                    World Champion of Public Speaking, ranging from overcoming
                    stage anxiety, preparing for that dreaded National Oral
                    Examinations (for students) or delivering that powerful
                    business presentation.
                  </p>
                  <div className="flex flex-col lg:flex-row space-y-5 lg:space-y-0 space-x-0 lg:space-x-5 items-center justify-between py-5">
                    <div className="w-full lg:w-1/2">
                      <img src="/images/speak-performance-1.jpg" alt="" />
                    </div>
                    <div className="w-full lg:w-1/2">
                      <img
                        src="/images/holiday-intensive-public-speaking masterclasses.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="py-5">
                    <h2 class="font-primary text-xl font-bold text-primary">
                      Meet our World Champion of Public Speaking for the 2-Day
                      Masterclass!
                    </h2>
                    <p class="pt-2 pb-5 font-text text-lg">
                      Essential soft skills such as public speaking and
                      interpersonal communication skills have become
                      increasingly important and highly sought after both in
                      schools and the modern workplace – from Direct School
                      Admission to Scholarship interviews, multimedia classroom
                      presentations to oral examinations and even leadership
                      roles / day-to-day interaction among students or
                      colleagues. Everyone wants to stand out and shine both in
                      school and at the workplace – the individuals who are able
                      to secure that promotion or lead the project are both
                      intelligent and more importantly, eloquent – possessing
                      the ability to cogently and persuasively deliver his/her
                      ideas to classmates, colleagues, employers, clients or
                      investors.
                    </p>
                    <p class="pt-2 pb-5 font-text text-lg">
                      Here at Public Speaking Academy, we believe that the
                      opportunity to pick up effective public speaking and
                      communication skills should be open to all. Based on our
                      primary research, many students and adults lack either
                      stage confidence, self-esteem or stage competencies, which
                      impairs the effectiveness of their communication skills.
                      Worse still, this can even create a vicious cycle of
                      students and adults reinforcing their self-limiting belief
                      that they were just born without the ‘gift of the gab’ or
                      that “Nature” trumps “Nurture”, therefore perpetuating the
                      deficiency of self-confidence!
                    </p>
                    <p class="pt-2 pb-5 font-text text-lg">
                      Instead, our team of professional public speaking trainers
                      strongly believe that essential soft skills such as public
                      speaking and interpersonal communication skills can
                      absolutely be nurtured and taught effectively. These
                      timeless communication skills and the clear benefits of
                      equipping your child or yourself with it will continue to
                      empower and transform your life immediately in your
                      workplace or your future path.
                    </p>
                  </div>
                  <div className="py-5">
                    <h2 class="font-primary text-xl font-bold text-primary">
                      What You Can Expect from the S’Peak Performance
                      Masterclasses
                    </h2>
                    <ul className="py-2 px-5 list-disc list-outside">
                      <li className="text-lg font-text pb-2">
                        <strong className="italic">
                          Personal “World Champion” Touch
                        </strong>{" "}
                        – our Master Trainer and World Champion of Public
                        Speaking 2016 will personally conduct the S’Peak
                        Performance Programme – sharing World-Class guidance,
                        and tried and tested public speaking and
                        confidence-boosting strategies
                      </li>
                      <li className="text-lg font-text pb-2">
                        <strong className="italic">
                          UNIQUE and FRESH Teaching Approach
                        </strong>{" "}
                        – creative and refreshing methods of monitoring progress
                        during the programme e.g. through the usage of video
                        recording to track performance and progress or using a
                        3-tier evaluation to facilitate improved learning curve
                      </li>
                      <li className="text-lg font-text pb-2">
                        <strong className="italic">
                          Flexible and Applicable Public Speaking Frameworks
                        </strong>{" "}
                        – be exposed to extensive public speaking frameworks
                        which are applicable in various contexts (from
                        one-on-one settings to effective business presentations)
                      </li>
                      <li className="text-lg font-text pb-2">
                        <strong className="italic">
                          Safe, Conducive and Constructive Environment
                        </strong>{" "}
                        – our students are provided with a safe, conducive and
                        constructive platform to practise their public speaking
                        skills and to make as many mistakes as possible and
                        learn from one another – this is a valuable opportunity
                        for our students to speak up before a group and hone the
                        practical aspects
                      </li>
                    </ul>
                    <p class="pt-2 pb-5 font-text text-lg">
                      From basic communication skills (e.g. body language, hand
                      gestures) to specific skills (e.g. interviews,
                      interpersonal communication), the S’Peak Performance
                      Programme is the public speaking training programme of
                      choice to ensure your continuous development as a powerful
                      public presenter or a confident communicator. Join us now!
                    </p>
                  </div>
                </div>
              </div>
              <div className="mx-auto">
                <div className="flex flex-col justify-center items-center mx-3 my-5 space-y-5 md:flex-row md:space-y-0 md:space-x-3">
                  <NavLink
                    to="/presentation-skills-training-course-by-world-champion"
                    className="px-3 py-2 text-center text-white bg-gray-800 hover:bg-btnColor w-60 uppercase rounded"
                  >
                    For Adult Learners
                  </NavLink>
                  <NavLink
                    to="/school-holiday-public-speaking-program-for-children"
                    className=" px-3 py-2 text-center text-white bg-gray-800 hover:bg-btnColor w-60 uppercase rounded"
                  >
                    For Students / Children
                  </NavLink>
                </div>
              </div>
            </div>
            <div class="hidden lg:inline lg:w-1/3 border border-dashed rounded-lg pt-5 px-5 mb-10">
              <Sidebar />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default IntensiveHolidayAdhoc;
