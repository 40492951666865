import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import FormMailer from "../components/FormMailer";

export default function GroupClassesAdults() {
  return (
    <>
      <Helmet>
        <title>
          Public Speaking Group Classes for Adults | Public Speaking Academy
        </title>
        <meta
          name="description"
          content="Led by Darren Tay, our public speaking training course coaches you effective communication and presentation skills in group. Contact us at 6443 4049 today!"
        ></meta>
      </Helmet>

      <section id="breadcrumb" className="bg-[#F5F5FC] py-1">
        <div className="container mx-auto">
          <div className="flex flex-row justify-between mx-3">
            <div>WORLD CHAMPION CERTIFICATION PROGRAMME</div>
            <div>
              Home / Courses & Programs / Public Speaking for Adults / Regular,
              Weekly Classes
            </div>
          </div>
        </div>
      </section>

      <section id="block-1" className="mx-3">
        <div class="container mx-auto py-20">
          <div class="flex flex-col lg:flex-row pt-5 space-x-10">
            <div class="w-full lg:w-2/3">
              <h1 class="font-primary text-3xl font-bold text-primary uppercase">
                World Champion Certification Programme (Public Speaking classes
                for Adults)
              </h1>
              <h2 class="font-primary text-2xl font-bold text-primary uppercase py-5">
                Regular Group Public Speaking Skills Training for Adults
                (Weekly)
              </h2>
              <div>
                <img src="/images/holiday-programme-1.png" alt="" />
                <p class="py-5 font-text text-lg">
                  Find yourself stumped, doubtful and nervous when asked to
                  deliver a high-stakes business presentation? Having trouble
                  communicating and motivating the team you have just been
                  assigned to lead? Or simply looking for a refreshing take on
                  effective communication skills and networking confidence
                  skills?
                </p>
                <p class="py-5 font-text text-lg">
                  At Public Speaking Academy, we recognise the modern struggle
                  that adults and working professionals face in public speaking,
                  business presentations & communications – indispensable skills
                  in the modern workplace. We believe in lifelong learning and
                  continuous skills upgrading, especially in ensuring that you
                  are not left behind in your fast-paced workplace. True to
                  this, the Public Speaking Academy has designed Singapore’s
                  first{" "}
                  <strong>
                    World Champion public speaking course for adults,
                  </strong>{" "}
                  tailored to meet the contemporary workplace demands. This
                  public speaking training program is designed by our Training
                  team, as led by Darren Tay and registered with the Ministry of
                  Education.
                </p>
                <div className="aspect-video">
                  <iframe
                    class="w-full h-full"
                    src="https://www.youtube.com/embed/CQnUd0r8SYo"
                    title="World Champion Certification Program - Course Intro"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                </div>
                <div className="py-5">
                  <p className="py-1 text-xl font-bold">
                    Public Speaking Academy Presents - the World Champion
                    Certification Programme!
                  </p>
                  <p className="py-1 text-xl font-bold">
                    (Public Speaking Academy’s signature presentation course for
                    adults since 2016)
                  </p>
                </div>
                <p className="text-lg font-text">
                  The World Champion Certification Programme is a public
                  speaking course for adults in Singapore designed for working
                  professionals by our first Singaporean World Champion of
                  Public Speaking, Darren Tay, Founder, Managing Director and
                  Master Trainer at the Public Speaking Academy. Tailored for
                  adults and working professionals, the World Champion
                  Certification Program will guide you through an introduction,
                  exploration, and enhancement of public speaking skills and
                  techniques through regular, group training sessions. These
                  consistent, week-in-week-out, engaging and holistic public
                  speaking training classes will help you with:
                </p>
                <ul className="p-5 list-disc list-outside">
                  <li className="text-lg font-text">
                    <strong>Communications Foundation-Building –</strong>{" "}
                    introduction to necessary foundation skills to enhance your
                    communication and start building your public speaking
                    learning journey (e.g. training classes on body language,
                    pronunciation, enunciation, vocal variety)
                  </li>
                  <li className="text-lg font-text">
                    <strong>
                      Expertise and Techniques for the Modern Communication
                      Settings –
                    </strong>{" "}
                    learn and master relevant and effective communication skills
                    to facilitate the growth of your potential as a confident
                    and charismatic communicator (e.g. training classes on
                    interview skills, PowerPoint presentation skills, persuasive
                    speech)
                  </li>
                  <li className="text-lg font-text">
                    <strong>Constructive and Safe Training Platform –</strong>{" "}
                    providing a safe space for you to grow as a public speaker
                    or effective communicator through practice opportunities,
                    constructive feedback and immediate feedback implementation
                    during every training class throughout the course
                  </li>
                  <li className="text-lg font-text">
                    <strong>Our Learning Methodology –</strong>{" "}
                    Constructively-Challenging & Sufficiently-Safe Learning
                    whereby public speaking theories are imparted in an
                    enthralling way, whilst being dove-tailed with ample
                    practical opportunities throughout the course
                  </li>
                  <li className="text-lg font-text">
                    <strong>
                      Our Qualified team of public speaking experts –
                    </strong>{" "}
                    our{" "}
                    <NavLink
                      className="font-text text-lg text-btnColor hover:underline"
                      to="/senior-trainers-trainers"
                    >
                      World Champion team of trainers
                    </NavLink>{" "}
                    are not recruited through job portals. Our qualified
                    trainers are competitive public speakers and/or presentation
                    trainers prior and are handpicked by our founder{" "}
                    <NavLink
                      className="font-text text-lg text-btnColor hover:underline"
                      to="/master-trainer"
                    >
                      Darren Tay
                    </NavLink>{" "}
                    himself. They are well-versed at our training syllabus and
                    pedagogy, and have shadowed Darren adequately in order to be
                    ready to helm our classes. They are also ACTA-trained and
                    registered with MOE.
                  </li>
                </ul>
                <div>
                  <h3 className="pb-5 font-bold font-primary text-xl py-4 text-center">
                    Course Objectives
                  </h3>
                  <p className="text-lg font-text">
                    At the end of this 9-week{" "}
                    <strong>public speaking skills training course,</strong> you
                    will be able to:
                  </p>
                  <ul className="py-2 px-5 list-disc list-outside">
                    <li className="text-lg font-text">
                      <strong>Confront your insecurities,</strong>{" "}
                      uncertainties, or fear of public speaking as a beginner in
                      various communication settings, and change your
                      relationship with business presentation anxieties
                    </li>
                    <li className="text-lg font-text">
                      <strong>
                        Develop competence in public speaking/ communicating
                        effectively
                      </strong>{" "}
                      before an audience, based on a given context (e.g.,
                      interview, speech, presentation)
                    </li>
                    <li className="text-lg font-text">
                      <strong>
                        Design a speech or presentation based on a given
                        framework,
                      </strong>{" "}
                      scaffold, and/or context (e.g., story-telling, sales
                      pitch)
                    </li>
                    <li className="text-lg font-text">
                      <strong>
                        Display an understanding of body language/non-verbal
                        communication techniques
                      </strong>{" "}
                      while public speaking to any audience, based on a given
                      context
                    </li>
                    <li className="text-lg font-text">
                      <strong>
                        Evaluate your presentation competency levels
                        independently,
                      </strong>{" "}
                      based on speech delivery (e.g., body language), speech
                      content (e.g., crafting content), and speech mindset
                      (e.g., stage fright management)
                    </li>
                  </ul>
                </div>

                <p class="pt-10 pb-5 font-text text-lg">
                  <b>Who should join this public speaking class?</b> World
                  Champion Certification Program (our signature Public Speaking
                  Course for Adults) is perfect for both beginners having their
                  first foray into the public speaking skills training journey
                  and advanced speakers looking to continue their communication
                  and presentation skills development.
                </p>
                <p class="pb-5 font-text text-lg">
                  <b>What can I expect from this course?</b> This training
                  program is conducted as weekly, regular group training
                  classes, with greater emphasis on an encouraging and engaging
                  learning environment. Unlike other one-way lecture-styled
                  courses, our competent and passionate training team delivers a
                  hands-on approach for optimal learning of public speaking
                  skills and presentation techniques – we believe that learning
                  is best achieved through a maximising combination of theory
                  and practice. We provide our students with the printed
                  handouts and video recordings of their practice speech
                  deliveries, maximising their learning by providing them with
                  an opportunity to evaluate their performance in class and
                  identify key areas of improvement moving forward.
                </p>
                <p class="pb-5 font-text text-lg">
                  <b>How big is the class?</b> We keep our class sizes small
                  (maximum 12 students) to ensure that each learner receives
                  dedicated focus and attention to their learning and
                  development from our trainers. You will get the opportunity to
                  review your learning each class in front of a sufficiently
                  challenging group size.
                </p>
                <p class="pb-5 font-text text-lg">
                  We provide our learners with a sufficiently safe, but still
                  constructive, challenging and conducive, training environment
                  for you to learn, train and grow as individuals and as a
                  group. Our public speaking classes are structured based on our
                  50 – 50 Rule:
                </p>
              </div>
              <div className="mx-auto">
                <img src="/images/50-50-Theory.png" alt="" />
              </div>
              <div className="flex flex-row mt-5 lg:space-x-4">
                <div className="p-3 text-lg border-r-2 border-gray-300 border-dashed w-1/2">
                  <p>
                    For every class, 50% of the class time is focused on an open
                    and engaging sharing of public speaking skills and
                    techniques, peppered with our professional trainers’
                    personal experiences and engagement activities & tools.
                  </p>
                </div>
                <div className="p-5 text-lg w-1/2">
                  <p>
                    For every class, 50% of the class time is dedicated to
                    providing our students with the safe and constructive space
                    to practice, practice and practice their craft (ample
                    opportunities to apply the skills taught)! They receive
                    professional, detailed, and dedicated evaluation every
                    training class.
                  </p>
                </div>
              </div>
              <p class="p-5 font-text text-lg">
                Our 50 – 50 Rule ensures that all our learners get a valuable
                opportunity to test their learning in a supportive environment,
                receive immediate and relevant feedback, and clarify their
                doubts in their public speaking learning journey. This prepares
                our learners to apply the experience to other speaking
                opportunities outside!
              </p>

              <div className="w-full bg-slate-100 rounded-xl">
                <div className="pt-10 pb-5">
                  <h2 className="text-xl font-bold text-center font-primary">
                    What Our Adult Learners Have To Say About The Program
                  </h2>
                </div>
                <div className="flex flex-col px-5 md:flex-row">
                  <div className="px-5 pb-10 md:w-1/2">
                    <div className="aspect-video">
                      <iframe
                        class="w-full h-full"
                        src="https://www.youtube.com/embed/ENYBYZDjL2s"
                        title="What Our Students Have To Say • Sebastien Debarge, WCCP Adult Public Speaking Program"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                      ></iframe>
                    </div>
                    <div className="py-2">
                      <p className="font-bold text-md font-primary">
                        Sebastien Debarge,
                      </p>
                      <p className="font-bold text-md font-primary">
                        Working professional (Pharmaceutical industry)
                      </p>
                      <p className="font-bold text-md font-primary">
                        Cohort 2019, WCCP Adult Lvl 1
                      </p>
                    </div>
                  </div>
                  <div className="px-5 pb-10 md:w-1/2">
                    <div className="aspect-video">
                      <iframe
                        class="w-full h-full"
                        src="https://www.youtube.com/embed/B-HWMbKVuxw"
                        title="What Our Students Have To Say • Nigel Eu, WCCP Adult Program"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                      ></iframe>
                    </div>
                    <div className="py-2">
                      <p className="font-bold text-md font-primary">
                        Nigel Eu,
                      </p>
                      <p className="font-bold text-md font-primary">
                        Fresh Graduate
                      </p>
                      <p className="font-bold text-md font-primary">
                        Cohort 2019, WCCP Adult Lvl 1
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col px-5 md:flex-row">
                  <div className="px-5 pb-10 md:w-1/2">
                    <img src="/images/testimonials-1.png" alt="" />
                  </div>
                  <div className="px-5 pb-10 md:w-1/2">
                    <img src="/images/testimonials-2.png" alt="" />
                  </div>
                </div>
                <div className="flex flex-col px-5 md:flex-row">
                  <div className="px-5 pb-10 md:w-1/2">
                    <img src="/images/testimonials-3.png" alt="" />
                  </div>
                  <div className="px-5 pb-10 md:w-1/2">
                    <img src="/images/testimonials-4.png" alt="" />
                  </div>
                </div>
                <div className="flex flex-col px-5 md:flex-row">
                  <div className="px-5 pb-10 md:w-1/2">
                    <img src="/images/testimonials-5.png" alt="" />
                  </div>
                  <div className="px-5 pb-10 md:w-1/2">
                    <img src="/images/testimonials-6.png" alt="" />
                  </div>
                </div>
              </div>

              <div className="my-10">
                <p className="pt-5 pb-5 text-xl italic font-bold">
                  Our Learning Areas and Scope – World Champion Certification
                  Programme (weekly public speaking classes for adults)
                </p>
                <p className="text-lg font-text">
                  The World Champion Certification Programme is designed as a
                  structured and guided learning process to tailor to students
                  from different public speaking background or with nuanced
                  learning objectives. The programme covers general
                  communication skills ranging from body language, vocal variety
                  to advanced public speaking skills/ techniques involving
                  specific speech genres and advanced linguistic skills.
                </p>
              </div>
              <section>
                <div className="py-5">
                  <h2 class="text-center text-4xl font-bold uppercase text-primary italic">
                    COURSE PRICING
                  </h2>
                  <h2 class="text-center text-xl font-bold py-2">
                    World Champion Certification Programme
                  </h2>
                </div>
                <div className="w-full mx-auto md:w-1/2">
                  <div className="py-10 border-2 border-gray-200 rounded-xl">
                    <h2 className="font-bold text-center font-primary">WCCP</h2>
                    <h2 className="py-3 text-4xl font-bold text-center font-primary text-iconColor2">
                      $688
                    </h2>
                    <p className="py-3 text-sm font-bold text-center uppercase font-primary text-primary">
                      Full programme cost (9 lessons)
                    </p>
                    <hr className="w-2/3 h-[3px] mx-auto bg-iconColor1" />
                    <div className="p-14">
                      <ul className="list-disc list-outside">
                        <li className="py-1 text-md font-text">
                          <b>9 weekly</b> lessons (approx. 2 months)
                        </li>
                        <li className="py-1 text-md font-text">
                          <b>Duration:</b> 2 Hours per session
                        </li>
                      </ul>
                    </div>
                    <div className="text-sm font-bold text-center uppercase font-primary text-primary">
                      *no registration fee, no material fee, no GST*
                    </div>
                  </div>
                </div>
              </section>

              <section className="my-10">
                <div className="py-5">
                  <h2 class="text-center text-2xl font-bold py-2">
                    Overview of Topics/ Syllabus
                  </h2>
                  <h3 class="text-center text-2xl font-bold py-2">
                    (Holistic Aspects of Public Speaking Skills Covered)
                  </h3>
                </div>
                <div className="w-full mx-auto md:w-2/3">
                  <div className="p-10 bg-orange-100 border border-orange-200 rounded-2xl">
                    <div>
                      <p className="pb-1 text-sm font-primary">
                        <b className="text-purple-700">
                          CORE FOCUS I: Speech Delivery
                        </b>{" "}
                        (communication techniques to build the foundation you
                        need to create a strong stage impression & confident
                        gloss)
                      </p>
                      <p className="pb-1 text-sm font-primary">
                        <b className="text-purple-700">Session 1:</b> Body
                        Language Foundation
                      </p>
                      <p className="pb-1 text-sm font-primary">
                        <b className="text-purple-700">Session 2:</b> Vocal
                        Quality, Variety & Clarity + Linguistic skills
                      </p>
                    </div>

                    <div className="my-3">
                      <p className="pb-1 text-sm font-primary">
                        <b className="text-purple-700">
                          CORE FOCUS II: Speech Content (Planned)
                        </b>{" "}
                        (speech structures for common communication
                        settings/themes)
                      </p>
                      <p className="pb-1 text-sm font-primary">
                        <b className="text-purple-700">Session 3:</b>{" "}
                        Story-Crafting & Audience Engagement
                      </p>
                      <p className="pb-1 text-sm font-primary">
                        <b className="text-purple-700">Session 4:</b>{" "}
                        Informative/Technical Presentations
                      </p>
                      <p className="pb-1 text-sm font-primary">
                        <b className="text-purple-700">Session 5:</b> Persuasive
                        Presentations
                      </p>
                      <p className="pb-1 text-sm font-primary">
                        <b className="text-purple-700">Session 6:</b> Presenting
                        with Visual Aids
                      </p>
                    </div>

                    <div className="my-3">
                      <p className="pb-1 text-sm font-primary">
                        <b className="text-purple-700">
                          CORE FOCUS III: Speech Content (Off-the-Cuff)
                        </b>{" "}
                        (speech techniques for fluid communication
                        settings/themes)
                      </p>
                      <p className="pb-1 text-sm font-primary">
                        <b className="text-purple-700">Session 7:</b> Impromptu
                        Speech
                      </p>
                      <p className="pb-1 text-sm font-primary">
                        <b className="text-purple-700">Session 8:</b> Interview
                        Skills (Pitching)
                      </p>
                      <p className="pb-1 text-sm font-primary">
                        <b className="text-purple-700">Session 9:</b>{" "}
                        Edge-of-the-seat Blueprint
                      </p>
                    </div>
                  </div>
                </div>
                <div className="container my-10">
                  <div>
                    <h3 className="font-primary text-xl">
                      Projected Outcomes upon Completion of World Champion
                      Certification Program
                    </h3>
                    <p className="text-lg font-text">
                      (based on feedback from Public Speaking Academy’s past
                      adult learners who have gone through this public speaking
                      skills training program in Singapore):
                    </p>
                    <ul className="py-2 px-5 list-disc list-outside">
                      <li className="text-lg font-text">
                        Enhanced non-verbal communication skills to{" "}
                        <b>
                          speak with greater impact and clarity in the workplace
                        </b>{" "}
                        to facilitate workplace operations, either intra or
                        cross-departmental communications
                      </li>
                      <li className="text-lg font-text">
                        Equipped with actionable public speaking & business
                        presentation skills to{" "}
                        <b>
                          keep the audience engaged, increase knowledge
                          retention
                        </b>{" "}
                        and augmenting the probability of desired actions taken
                        by audience members, within the company/ organization or
                        external stakeholders.
                      </li>
                      <li className="text-lg font-text">
                        Equipped with easy-to-execute impromptu speaking
                        frameworks to be{" "}
                        <b>
                          adequately prepared for spontaneous public speaking
                          settings, internal or public briefings, meetings, Q&A
                        </b>{" "}
                        at seminars etc.
                      </li>
                      <li className="text-lg font-text">
                        Able to{" "}
                        <b>
                          be clear and effective with spoken words and
                          articulation
                        </b>{" "}
                        for greater clarity and impact in presentations and
                        communications
                      </li>
                      <li className="text-lg font-text">
                        Equipped with story-telling techniques to engage your
                        audience and sustain their attention:{" "}
                        <b>Make what you say more persuasive and riveting</b>{" "}
                        (persuasive speaking techniques)
                      </li>
                      <li className="text-lg font-text">
                        Able to lead <b>with Charisma & Eloquence:</b> Using
                        communication skills to influence and charm
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="container my-10">
                  {/* <h2 class="text-primary font-primary font-bold uppercase text-xl md:text-xl text-center py-5">
                    Our Branch Locations
                  </h2> */}
                  <img
                    src="/images/image-of-WCCP-adult-public-speaking-presentation-skills-training-program-schedule-intakes-2.png"
                    className="my-5"
                    alt=""
                  />
                  <p class="font-text text-lg md:text-lg  text-textColor py-3">
                    If you would like to request for a customised course timing
                    for your group of friends or colleagues (at Public Speaking
                    Academy’s premises or at your own preferred premises in
                    Singapore), please contact us and indicate your request in
                    the contact form below. We will contact you shortly. Please
                    be informed that we have a minimum class size for a
                    customised course timing.
                  </p>
                  <p class="font-text text-lg md:text-lg  text-textColor py-3">
                    If you are a parent and are also looking for public speaking
                    courses to enrich your kids’ skill-sets, you may check out
                    our{" "}
                    <NavLink
                      to="/group-classes-students-age-7-18/"
                      className="font-text text-lg text-btnColor hover:underline"
                    >
                      public speaking courses for kids here.
                    </NavLink>
                  </p>
                  <div class="flex flex-col md:flex-row space-y-3 md:space-y-0 md:space-x-3 my-5">
                    <img
                      class="md:w-1/3 w-full h-auto rounded-2xl border-r-4 border-b-4 border-[#FFE4D5]"
                      src="/images/group-classes-for-adults-block-1.jpg"
                      alt=""
                    />
                    <img
                      class="md:w-1/3 w-full h-auto rounded-2xl border-r-4 border-b-4 border-[#FFE4D5]"
                      src="/images/group-classes-for-adults-block-2.jpg"
                      alt=""
                    />
                    <img
                      class="md:w-1/3 w-full h-auto rounded-2xl border-r-4 border-b-4 border-[#FFE4D5]"
                      src="/images/group-classes-for-adults-block-3.jpg"
                      alt=""
                    />
                  </div>
                </div>
                <div>
                  <h2 class="text-primary font-primary font-bold uppercase text-xl md:text-xl text-center py-5">
                    Frequently Asked Questions
                  </h2>
                  <div
                    tabIndex={0}
                    className="border collapse collapse-arrow border-gray-200 rounded-box bg-white"
                  >
                    <div className="text-xl font-medium collapse-title">
                      How long is each session?
                    </div>
                    <div className="collapse-content">
                      <p>2 Hours</p>
                    </div>
                  </div>
                  <div
                    tabIndex={1}
                    className="border collapse collapse-arrow border-gray-200 rounded-box bg-white"
                  >
                    <div className="text-xl font-medium collapse-title">
                      Who teaches the lessons?
                    </div>
                    <div className="collapse-content">
                      <p>
                        Professional trainer from Darren Tay’s teaching faculty.
                      </p>
                    </div>
                  </div>
                  <div
                    tabIndex={2}
                    className="border collapse collapse-arrow border-gray-200 rounded-box bg-white"
                  >
                    <div className="text-xl font-medium collapse-title">
                      What will the class demographics be for this adult public
                      speaking class?
                    </div>
                    <div className="collapse-content">
                      <p>
                        Class demographics is diverse and inclusive. We actually
                        have students from a range of age groups and
                        professional backgrounds, ranging from teens (18-19
                        students), to 20s, to 40s; we have had undergraduates,
                        polytechnic/ junior college studentsas well as retirees.
                        Our student body consists of a whole range of
                        occupations: newly-hired, mid-level managers, sales
                        agents, self-employed, nurses, teachers, company
                        executives etc. (Communication skills are needed in
                        every single industry - which is why our student
                        demographics are pretty diverse 😊👍).
                      </p>
                    </div>
                  </div>
                  <div
                    tabIndex={3}
                    className="border collapse collapse-arrow border-gray-200 rounded-box bg-white"
                  >
                    <div className="text-xl font-medium collapse-title">
                      How is payment done?
                    </div>
                    <div className="collapse-content">
                      <p>
                        Upon confirmation and registration of participant (via
                        the receipt of the completed registration form),
                        participant will make full payment of the program fee in
                        order to secure the his/ her seat.
                      </p>
                      <p>
                        Payment can be made through the following modes:
                        <br />
                        1. PayNow
                        <br />
                        2. Fund Transfer
                        <br />
                        3. PayPal (for credit card payments)
                      </p>
                    </div>
                  </div>
                  <div
                    tabIndex={2}
                    className="border collapse collapse-arrow border-gray-200 rounded-box bg-white"
                  >
                    <div className="text-xl font-medium collapse-title">
                      What if I miss a lesson?
                    </div>
                    <div className="collapse-content">
                      <p>
                        We do not have any lessons on designated public
                        holidays. However, for any reason that you cannot make
                        it for lessons on regular lesson days, fret not.
                        Although we do not have a replacement class policy, we
                        will assist you in your learning and ensure that you
                        will not be left behind by helping you in 2 areas:
                        <br />
                        1. We offer you all the notes and materials used by the
                        trainer during the lesson which you missed.
                        <br />
                        2. We offer you our online training video(s), featuring
                        our World Champion of Public Speaking, Darren Tay, for
                        the respective lesson(s) missed.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="px-5 pt-5 my-20 mb-10 border border-gray-400 border-dashed rounded-lg">
                  <h3 className="pb-2 text-2xl font-bold text-center font-primary text-primary">
                    To register, please fill up the contact form below and we
                    will process your registration for you.
                  </h3>
                  <FormMailer />
                </div>
              </section>
            </div>
            <div className="hidden lg:inline lg:w-1/3 px-5 pt-5 mb-10 border border-dashed rounded-lg">
              <Sidebar />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
