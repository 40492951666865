import Header from "../components/Header";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function Competitions() {
  return (
    <>
      <Helmet>
        <title>
          National Public Speaking Competition | Public Speaking Academy
        </title>
        <meta
          name="description"
          content="The National Public Speaking Competition is a competitive and challenging environment for young public speakers in Singapore. Led by top public speaker, Darren Tay."
        />
      </Helmet>

      <section id="breadcrumb" className="bg-[#F5F5FC] py-1">
        <div className="container mx-auto">
          <div className="flex justify-between mx-3">
            <div>NATIONAL PUBLIC SPEAKING COMPETITION</div>
            <div>Home / Competitions</div>
          </div>
        </div>
      </section>

      <section id="latest-blog">
        <div className="container mx-auto">
          <div className="pt-10 mx-3 md:pt-24">
            <h1 className="text-4xl font-bold font-primary text-primary">
              National Public Speaking Competition 2022{" "}
            </h1>
            <p className="my-3 text-2xl font-primary text-textColor">
              (Registrations for 2022 now open for Secondary Category!)
            </p>
          </div>

          <div className="flex flex-col items-center mx-3 my-10 lg:flex-row lg:space-x-5">
            <div className="w-full my-3 lg:w-1/2">
              <iframe
                src="https://www.youtube.com/embed/sg3StuNi0cg"
                frameborder="0"
                allowfullscreen
                className="w-full rounded-lg aspect-video"
                title="How to Think Smart and Talk Fast"
              ></iframe>
            </div>
            <div className="w-full my-3 lg:w-1/2">
              <h2 className="text-2xl font-bold font-primary">We are back!</h2>
              <p className="py-4 text-lg font-text">
                Want to pit yourself against the best public speakers in
                Singapore? Find out where you stand as a public speaker on a
                national level by joining us at the National Public Speaking
                Competition!
              </p>
              <p className="py-4 text-lg font-text">
                The Public Speaking Academy has been organising the National
                Public Speaking Competition for our Primary and Secondary school
                students since 2010. While we took a break for COVID-19, the
                Competition will be returning in 2022 onwards as we bring you
                the best public speakers in town! This is your invitation to
                join the community of champion speakers and bring your skills to
                the next level.
              </p>
              <p className="text-lg font-text">
                Check out our highlight reel to find out more or swing by our
                YouTube Channel to watch the other young and confident speakers!
              </p>
            </div>
          </div>
          <div className="px-5 py-10 bg-orange-100 rounded-xl">
            <h2 className="text-2xl font-bold font-primary">
              What is the National Public Speaking Competition?
            </h2>
            <p className="py-4 text-lg font-text">
              The National Public Speaking Competition is a public speaking
              competition for students in the Primary and Secondary categories
              to challenge themselves against other speakers in Singapore. As
              the organiser, we encourage a competitive and challenging
              environment for our young public speakers to push themselves in
              prepared and impromptu speeches. Beyond a contest, the National
              Public Speaking Competition fosters a young, growing public
              speaking community, with the opportunity to engage in
              skills-exchanges and networking opportunities. The main objectives
              of the Competition are:
            </p>
            <div className="flex flex-col space-y-5 lg:flex-row lg:space-x-5 lg:space-y-0">
              <div className="rounded-xl bg-[#B9B9E1] p-5">
                <h2 className="text-2xl font-bold text-center text-white font-primary">
                  Encourage
                </h2>
                <p className="py-4 text-lg font-text">
                  Encourage our young participants to explore and nurture an
                  interest in public speaking, while honing their skills on a
                  nation-wide platform
                </p>
              </div>
              <div className="rounded-xl bg-[#B9B9E1] p-5">
                <h2 className="text-2xl font-bold text-center text-white font-primary">
                  Showcase
                </h2>
                <p className="py-4 text-lg font-text">
                  Showcase and celebrate the varied talents of all the
                  communicators, especially in providing a professional platform
                  for them to shine and grow
                </p>
              </div>
              <div className="rounded-xl bg-[#B9B9E1] p-5">
                <h2 className="text-2xl font-bold text-center text-white font-primary">
                  Challenge
                </h2>
                <p className="py-4 text-lg font-text">
                  Challenge our young communicators to think critically,
                  coherently and creatively, in crafting their speeches and
                  managing impromptu settings
                </p>
              </div>
              <div className="rounded-xl bg-[#B9B9E1] p-5">
                <h2 className="text-2xl font-bold text-center text-white font-primary">
                  Foster
                </h2>
                <p className="py-4 text-lg font-text">
                  Foster a growing community of speakers, from beginners to
                  experienced speakers, in a conducive and interactive
                  environment for shared learning
                </p>
              </div>
            </div>
            <p className="py-4 text-lg font-text">
              The National Public Speaking Competition remains the preferred
              platform by schools to invest in the effective communication
              potential of their students – in 2017, the Competition entered the
              Singapore Book of Records as the largest public speaking
              competition in Singapore! We look forward to welcoming our next
              set of talented speakers, with your support. Find out more about
              the key details of the Competition below!
            </p>
          </div>
        </div>
      </section>
      <section id="competition-format">
        <div className="container mx-auto my-10">
          <div className="mx-3">
            <div className="py-10">
              <h3 className="text-xl italic text-center font-primary text-primary">
                The Competition Format
              </h3>
            </div>
            <div className="flex flex-col space-y-5 md:flex-row md:space-y-0">
              <div className="w-full text-center md:w-1/3">
                <h3 className="py-2 text-lg font-bold uppercase font-primary text-primary">
                  Registration
                </h3>
                <div className="py-2">
                  <span className="p-5 font-semibold text-white rounded-full material-symbols-rounded bg-iconColor1 lg:text-3xl xl:text-6xl">
                    mail
                  </span>
                </div>
                <p className="px-10 py-2 font-text">
                  Each school can send a maximum of 10 contestants – schools are
                  encouraged to conduct their internal selection for their
                  representatives. We welcome independent applicants as well.
                </p>
              </div>
              <div className="w-full text-center md:w-1/3">
                <h3 className="py-2 text-lg font-bold uppercase font-primary text-primary">
                  QUALIFYING ROUNDS
                </h3>
                <div className="py-2">
                  <span className="p-5 font-semibold text-white rounded-full material-symbols-rounded bg-iconColor1 lg:text-3xl xl:text-6xl">
                    thumb_up
                  </span>
                </div>
                <p className="px-10 py-2 font-text">
                  Our panel of professional adjudicators will select 10 students
                  to advance into the Grand Finals (fbased on each Category)
                </p>
              </div>
              <div className="w-full text-center md:w-1/3">
                <h3 className="py-2 text-lg font-bold uppercase font-primary text-primary">
                  GRAND FINALS
                </h3>
                <div className="py-2">
                  <span className="p-5 font-semibold text-white rounded-full material-symbols-rounded bg-iconColor1 lg:text-3xl xl:text-6xl">
                    workspace_premium
                  </span>
                </div>
                <p className="px-10 py-2 font-text">
                  Our panel of professional adjudicators will select the
                  Champion, 1st and 2nd Runners-Up for the Competition (for each
                  category). The Champion will be crowned the National Champion
                  of Public Speaking.
                </p>
              </div>
            </div>
            <div className="py-10">
              <p className="text-lg italic text-center font-text">
                In recognition of the intense competition to reach the Grand
                Finals, all participants will be awarded a trophy for their
                achievement of advancing into the finals.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="rules-regulation">
        <div className="container mx-auto my-10">
          <div className="mx-3">
            <div className="py-10">
              <h3 className="text-xl italic text-center font-primary text-primary">
                Key Rules & Regulations
              </h3>
            </div>
            <div>
              <p className="text-lg font-text">
                While the Rules & Regulation for the Competition can be found in
                our InfoKit, we have set out some key eligibility requirement
                here for your reference:
              </p>
              <ol className="p-5 list-decimal list-outside">
                <li className="my-2 text-lg font-text">
                  <strong>Qualifiying Age Group:</strong> Contestants must be
                  within the national age group of the respective contest
                  levels/categories below (independent contestants will be
                  grouped based on their age group).
                  <ul className="p-5 list-disc list-outside">
                    <li className="text-lg font-text">
                      Primary Category (7 to 12 years old, based on year of
                      birth)
                    </li>
                    <li className="text-lg font-text">
                      Secondary Category (13 to 17 years old, based on year of
                      birth)
                    </li>
                  </ul>
                </li>
                <li className="my-2 text-lg font-text">
                  <strong>Deadline Requirement:</strong> Contestants must be
                  registered with the organisers before the stipulated deadline
                  for the National Public Speaking Competition (to be
                  confirmed).
                </li>
                <li className="my-2 text-lg font-text">
                  <strong>Originality Requirement:</strong>Every speech
                  submission must be original, personal product and contestants
                  must not plagiarise speeches from print media, website(s), or
                  other uncredited sources. Any quoted material should be cited
                  appropriately in the speech.
                </li>
                <li className="my-2 text-lg font-text">
                  <strong>Content Requirement:</strong>Contestants are not
                  allowed to use potentially offensive/rude language in their
                  speeches. The themes for their speeches should avoid sensitive
                  issues such as (but not limited to) religious, political,
                  racial, gender, sexual inneundoes, discriminatory themes. This
                  aside, as an open-theme public speaking competition,
                  contestants are allowed to choose any topic as part of their
                  national prepared speech and are not limited to any particular
                  speech genre (i.e. they are allowed to present a persuasive
                  speech, an inspirational speech or an informative speech).
                </li>
                <li className="my-2 text-lg font-text">
                  <strong>Standing Requirement:</strong>Contestants must be of
                  good standing (e.g., full-time student pursuing a primary
                  education at an approved institution)
                </li>
              </ol>
              <p className="text-lg font-text">
                The Organising Committee of the Competition reserves the right
                to disqualify, bar, or remove a Contestant from the Competition
                based on an infringement of the Rules & Regulations. Any protest
                or objection lodged is only limited to grounds of originality
                and eligibility – any other grounds will not be entertained
                unless on an exceptional basis. If Contestants wish to raise a
                point of objection or protest during the Competition, he/she may
                do so by raising it to the Chief Adjudicator/Tournament Director
                directly. The assessment of all protests will be subject to the
                discretion of the panel of adjudicators and Organising
                Committee, and their decision will be final, conclusive, and not
                subject to further appeal.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="competition-format">
        <div className="container mx-auto my-10">
          <div className="mx-3">
            <div className="py-10">
              <h3 className="text-xl italic text-center font-primary text-primary">
                Format of Competition
              </h3>
            </div>
            <div className="py-4">
              <p className="my-2 text-lg font-text">
                The Competition consists of two Rounds - the Qualifying Round
                and Grand Finals. The key details for each round are set out
                below:
              </p>
            </div>
            <div className="py-4">
              <p className="my-2 text-lg underline font-text">
                <strong>
                  National Public Speaking Competition Contest Component –
                  Qualifying Rounds
                </strong>
              </p>
              <p className="my-2 text-lg italic font-text">
                <strong>National Prepared Speech Component (100%)</strong>
              </p>

              <p className="my-2 text-lg font-text">
                <strong>Description:</strong> Contestants will prepare a speech
                based on the specified requirements for the Competition - they
                will present this before our panel of adjudicators who will
                decide the top 10 (for each Category) to move to the Grand
                Finals. For this Round, 100% of the scores will be determined by
                the speech delivered in the Qualifying Round.
              </p>
              <p className="my-2 text-lg font-text">
                <strong>Speech Duration:</strong> 3 to 4 min
              </p>
            </div>

            <div className="py-4">
              <p className="my-2 text-lg underline font-text">
                <strong>
                  National Public Speaking Competition Contest Component – Grand
                  Finals
                </strong>
              </p>
              <p className="my-2 text-lg italic font-text">
                <strong>
                  National Prepared Speech Component (50%) + National Impromptu
                  Speech Component (50%)
                </strong>
              </p>

              <p className="my-2 text-lg font-text">
                <strong>Description:</strong> Contestants will present their
                prepared speech based on the specified requirements for the
                Competition and respond to an impromptu topic on the spot (which
                will only be given on stage). Our panel of adjudicators will
                decide the National Champion, 1st, and 2nd Runners-Up for each
                category. Each component form 50% of the final scores for the
                Grand Finals.
              </p>
              <p className="my-2 text-lg font-text">
                <strong>Speech Duration:</strong> 3 to 4 min for Prepared
                Speech; 1 to 2 min for Impromptu Speech
              </p>
            </div>
            <div className="mx-auto">
              <div className="flex flex-row items-center justify-center py-4 text-center">
                <p className="p-4 text-sm text-orange-800 bg-orange-100 rounded-full h-28 w-28 sm:h-40 sm:w-40 sm:p-8 sm:text-base lg:h-48 lg:w-48 lg:p-10 lg:text-xl">
                  <strong>National</strong>
                  <br />
                  <strong>Prepared</strong>
                  <br />
                  <strong>Speech</strong>
                  <br />
                  (50%)
                </p>
                <p className="p-3 text-2xl font-bold text-orange-800 md:p-7">
                  +
                </p>

                <p className="p-4 text-sm text-orange-800 bg-orange-100 rounded-full h-28 w-28 sm:h-40 sm:w-40 sm:p-8 sm:text-base lg:h-48 lg:w-48 lg:p-10 lg:text-xl">
                  <strong>National</strong>
                  <br />
                  <strong>Impromptu</strong>
                  <br />
                  <strong>Speech</strong>
                  <br />
                  (50%)
                </p>
                <p className="p-3 text-2xl font-bold text-orange-800 md:p-7">
                  =
                </p>
                <p className="p-4 text-sm text-orange-800 bg-orange-100 rounded-full h-28 w-28 sm:h-40 sm:w-40 sm:p-8 sm:text-base lg:h-48 lg:w-48 lg:p-10 lg:text-xl">
                  <strong>Judging</strong>
                  <br />
                  <strong>Criteria</strong>
                  <br />
                  (100%)
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="timing-details">
        <div className="container mx-auto my-10">
          <div className="mx-3">
            <div className="py-5">
              <h3 className="text-xl italic text-center font-primary text-primary">
                Timing Details (for Speech)
              </h3>
            </div>
            <div>
              <p className="my-2 text-lg font-text">
                A timing device (based on a lights-system) will be stationed at
                the contest venue on the day of the competition. For the
                National Prepared Speech Component (both Qualifying Round &
                Grand Final), contestants must speak for at least 3 mins and not
                more than 4 mins 30 secs. For the National Impromptu Speech
                Component (for Grand Final), contestants must speak for at least
                1 min and not more than 2 mins 30 secs. Contestants must sum up
                their respective speeches within 30 secs from when the red light
                is switched on. There will be no buzzer, ringing, or any audible
                devices used to signify that the contestant has exceeded the
                time limit.
                <br />
                Failure to adhere to the timing details stipulated above will
                result in immediate disqualifcation - this decision is final and
                not subject to appeal. We have set out the timing sequence below
                as well:
              </p>
            </div>
          </div>
          <div className="flex flex-col mx-3 space-x-0 space-y-10 md:flex-row md:space-x-5 md:space-y-0">
            <div className="w-full md:w-1/2">
              <img
                className="w-full h-auto my-5"
                src="/images/comp-4.png"
                alt=""
              />
              <p className="text-center text-sky-600">
                Timing Sequence for National Prepared Speech Component
              </p>
            </div>
            <div className="w-full md:w-1/2">
              <img
                className="w-full h-auto my-5"
                src="/images/comp-5.png"
                alt=""
              />
              <p className="text-center text-sky-600">
                Timing Sequence for National Impromptu Speech Component
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="timing-details">
        <div className="container mx-auto my-10">
          <div className="mx-3">
            <div className="py-5">
              <h3 className="text-xl italic text-center font-primary text-primary">
                Date & Time
              </h3>
            </div>
            <div>
              <p className="my-2 text-lg font-text">
                The Organising Committee is currently confirming the dates and
                time for our 2022 run which is planned to be in-person for both
                the Qualifying Rounds and Grand Final. For an indication, the
                dates and time for our previous run in 2018 is set out below for
                reference:
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="primary-category">
        <div className="container mx-auto my-10">
          <div className="mx-3">
            <div className="py-5">
              <h3 className="text-xl italic text-center font-primary text-primary">
                Primary Category
              </h3>
            </div>
            <div>
              <table className="w-full text-left border table-auto">
                <thead className=" bg-[#D0E7FC]">
                  <tr className="text-left">
                    <th>Level of contest</th>
                    <th>Date / Time</th>
                    <th>Venue</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Qualifying rounds</td>
                    <td>
                      Currently scheduled for 2023
                      <br />
                      17 October 2018; 1.30pm – 6pm
                    </td>
                    <td>
                      YMCA of Singapore
                      <br />1 Orchard Road
                      <br />
                      Level 4, Function rooms 1, 2 and 3
                    </td>
                  </tr>
                  <tr>
                    <td>Grand Finals</td>
                    <td>
                      Currently scheduled for 2023
                      <br />
                      23 October 2018; 1.30pm – 6pm
                    </td>
                    <td>
                      YMCA of Singapore
                      <br />1 Orchard Road
                      <br />
                      Level 1, LKC Auditorium
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
      <section id="primary-category">
        <div className="container mx-auto my-10">
          <div className="mx-3">
            <div className="py-5">
              <h3 className="text-xl italic text-center font-primary text-primary">
                Secondary Category
              </h3>
            </div>
            <div>
              <table className="w-full text-left border table-auto">
                <thead className=" bg-[#D0E7FC]">
                  <tr className="text-left">
                    <th>Level of contest</th>
                    <th>Date / Time</th>
                    <th>Venue</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Qualifying rounds</td>
                    <td>
                      17, 18 Oct 2022, 2 to 6 pm
                      <br />
                      (Schools will be assigned a date and time-slot,
                      <br />
                      and will need to arrange their own transportation)
                    </td>
                    <td>
                      Public Speaking Academy
                      <br />
                      SAFRA Tampines Branch
                      <br />
                      1 Tampines St 92, #01-13
                      <br />
                      Singapore 528882
                    </td>
                  </tr>
                  <tr>
                    <td>Grand Finals</td>
                    <td>
                      27 Oct 2022, 2 to 6 pm
                      <br />
                      (Schools will need to arrange their own
                      <br />
                      transportation)
                    </td>
                    <td>
                      YMCA of Singapore
                      <br />1 Orchard Road
                      <br />
                      Level 1, LKC Auditorium
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
      <section id="primary-category">
        <div className="container mx-auto my-10">
          <div className="mx-3">
            <div className="py-5">
              <h3 className="text-xl italic text-center font-primary text-primary">
                Hall of Fame / Past Winners
              </h3>
            </div>
            <div>
              <p className="my-2 text-lg font-text">
                We are extremely proud of our past Champions for the National
                Public Speaking Competition – their accomplishments as young
                public speakers will be recorded here in recognition of their
                immense achievement:
              </p>
            </div>
            <div className="flex flex-col items-center justify-between space-y-10 lg:flex-row lg:space-x-10 lg:space-y-0">
              <div className="w-full lg:max-w-1/3 lg:w-1/3">
                <iframe
                  src="https://www.youtube.com/embed/cqTdfF2DUJc"
                  frameborder="0"
                  allowfullscreen
                  className="w-full rounded-lg aspect-video"
                ></iframe>
              </div>
              <div className="w-full lg:max-w-1/3 lg:w-1/3">
                <iframe
                  src="https://www.youtube.com/embed/dRA4WgWZHHo"
                  frameborder="0"
                  allowfullscreen
                  className="w-full rounded-lg aspect-video"
                ></iframe>
              </div>
              <div className="w-full lg:max-w-1/3 lg:w-1/3">
                <iframe
                  src="https://www.youtube.com/embed/_A9eEBY9dG0"
                  frameborder="0"
                  allowfullscreen
                  className="w-full rounded-lg aspect-video"
                ></iframe>
              </div>
            </div>
            <div className="mx-auto text-center my-14">
              <NavLink
                className="px-10 py-3 text-lg font-bold text-white rounded-md bg-btnColor font-primary"
                to="/hall-of-fame"
              >
                Check All Our Past Winners
              </NavLink>
            </div>
            <div>
              <p className="my-2 text-lg font-text">
                For some inspiration, feel free to swing by our YouTube channel
                and view the award-winning speeches from previous iterations of
                the National Public Speaking Competition!
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="competition-format">
        <div className="container mx-auto my-10">
          <div className="mx-3">
            <div className="py-5">
              <h3 className="text-xl italic text-center font-primary text-primary">
                I am Interested!
              </h3>
            </div>
            <div>
              <p className="my-2 text-lg font-text">
                We are ready to welcome you for our National Public Speaking
                Competition 2022 (Secondary School Category - 13 to 17 years
                old)! Students may choose to join as a representative of their
                school (in which case, registration must be done via the school)
                or as an Independent contestant (if the school is not sending
                representatives). The registration details are as follows:
              </p>
              <p className="my-2 text-lg font-text">
                <strong>Registration form:</strong> please complete and submit
                the registration form via this link:
                https://forms.gle/a4ftLmUv3ezFHYVc7
              </p>
              <p className="my-2 text-lg font-text">
                <strong>Multiple Contestants:</strong> If you have multiple
                representatives from your school download our CSV/Excel File
                (click here!). Complete the file with all the required details
                and send it by the deadline via email with the header “NPSC 2022
                Contestant Registration” to contact@publicspeakingacademy.com.sg
                (please download and edit the file instead of attempting to edit
                the document online).
              </p>
              <p className="my-2 text-lg font-text">
                <strong>Registration Fee:</strong> The registration fee is S$50
                per contestant (used to cover venue bookings,
                certificates/trophies, administrative matters etc.). Payment can
                be done via e-invoice (for schools) or other options (PayNow,
                Bank Transfer, PayPal) – our team will reach out once the
                registration details are in order. (Note: The registration fee
                is waived for existing learners at Public Speaking Academy –
                please inform us if this applies to you or any of the
                contestants!)
              </p>
              <p className="my-2 text-lg font-text">
                <strong>Deadline:</strong> both the registration form and
                payment for all contestants must be completed by 31 Aug 2022,
                2359 hrs. As per our Rulebook, late submissions will not be
                accepted and no extensions will be given.
              </p>
              <p className="my-2 text-lg font-text">
                <strong>Guides:</strong> You may view our Rulebook here. For
                schools registering their representatives, you may view our
                InfoKit here.
              </p>
              <p className="my-2 text-lg font-text">
                We look forward to seeing your registration and joining us in
                nurturing the future champion speakers! If you have any queries
                before registering, feel free to reach out to us at
                contact@publicspeakingacademy.com.sg and indicate “NPSC” in the
                Subject header!
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
