import Footer from "../components/Footer";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
export default function CoursesAndPrograms() {
  return (
    <>
      <Helmet>
        <title>Courses and Programs | Public Speaking Academy</title>
        <meta
          name="description"
          content="Courses and Programs for Public Speaking Training led by top public speaker, Darren Tay."
        ></meta>
      </Helmet>

      <section id="breadcrumb" className="bg-[#F5F5FC] py-1">
        <div className="container mx-auto">
          <div className="flex justify-between mx-3">
            <div>Courses and Programs</div>
            <div>Home / Courses and Programs</div>
          </div>
        </div>
      </section>

      <section id="courses-programs" className="py-5 mx-3">
        <div className="container mx-auto">
          <div className="flex flex-col space-y-5 md:flex-row md:space-y-0 md:space-x-10">
            <div className="flex items-center w-full md:w-1/2 h-[500px] rounded-3xl courses-program-left">
              <div className="mx-auto">
                <h3 className="px-8 py-2 mx-3 text-xl font-bold text-center text-white uppercase bg-primary font-primary">
                  Public speaking for ADULTS
                </h3>
                <div className="flex flex-col justify-between mx-3 my-5 space-y-5 md:flex-row md:space-y-0 md:space-x-3">
                  <NavLink
                    to="/courses-and-programs/group-classes-for-adults"
                    className="px-3 py-2 text-center text-white bg-gray-800 hover:bg-btnColor"
                  >
                    9-Week Programme
                  </NavLink>
                  <NavLink
                    to="/presentation-skills-training-course-by-world-champion"
                    className="px-3 py-2 text-center text-white bg-gray-800 hover:bg-btnColor"
                  >
                    Masterclasses/Bootcamps
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="flex items-center w-full md:w-1/2 h-[500px] rounded-3xl courses-program-right">
              <div className="mx-auto">
                <h3 className="px-8 py-2 mx-3 text-xl font-bold text-center text-white uppercase bg-primary font-primary">
                  Public speaking for KIDS
                </h3>
                <div className="flex flex-col justify-between mx-3 my-5 space-y-5 md:flex-row md:space-y-0 md:space-x-3">
                  <NavLink
                    to="/group-classes-students-age-7-18"
                    className="px-3 py-2 text-center text-white bg-gray-800 hover:bg-btnColor"
                  >
                    Regular Weekly Classes
                  </NavLink>
                  <NavLink
                    to="/school-holiday-public-speaking-program-for-children"
                    className="px-3 py-2 text-center text-white bg-gray-800 hover:bg-btnColor"
                  >
                    Holiday Camps/Masterclasses
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
