import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import { useEffect, useState } from "react";
import "../index.css";

export default function ViewPost() {
  const { slug } = useParams();
  const [post, setPost] = useState({});
  useEffect(() => {
    //axios
    let url = `${process.env.REACT_APP_API_ROOT}/posts?slug=${slug}`;
    console.log(url);
    axios
      .get(url)
      .then((res) => {
        console.log("res", res);
        setPost(res.data[0]);
      })
      .catch((err) => {
        console.log("error:", err.message);
      });
  }, []);
  return (
    <>
      {Object.keys(post).length ? (
        <Helmet>
          <title>{post.title.rendered}</title>
          <meta name="description" content={post.excerpt.rendered} />
        </Helmet>
      ) : (
        "Loading..."
      )}
      {Object.keys(post).length ? (
        <div>
          <section id="breadcrumb" className="bg-[#F5F5FC] py-1">
            <div className="container mx-auto">
              <div className="flex justify-between mx-3">
                <div
                  dangerouslySetInnerHTML={{ __html: post.title.rendered }}
                ></div>
                <div>Home / Blog</div>
              </div>
            </div>
          </section>

          <section id="block-1">
            <div className="container mx-auto py-20">
              <div className="flex flex-col lg:flex-row pt-5 space-x-10 mx-3">
                <div className="w-full lg:w-2/3">
                  <h1
                    className="font-primary text-3xl font-bold text-primary text-center"
                    dangerouslySetInnerHTML={{ __html: post.title.rendered }}
                  ></h1>
                  <div
                    className="blog-content"
                    dangerouslySetInnerHTML={{ __html: post.content.rendered }}
                  ></div>
                </div>
                <div className="hidden lg:inline lg:w-1/3 px-5 pt-5 mb-10 border border-dashed rounded-lg">
                  <Sidebar />
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        "Loading..."
      )}
      ;
      <Footer />
    </>
  );
}
