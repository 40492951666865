import Header from "../components/Header";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function HallOfFame() {
  return (
    <>
      <Helmet>
        <title>Hall of Fame | Public Speaking Academy</title>
        <meta
          name="description"
          content="Led by Darren Tay, our public speaking training course coaches you effective communication and presentation skills in group. Contact us at 6443 4049 today!"
        ></meta>
      </Helmet>

      <section id="breadcrumb" className="bg-[#F5F5FC] py-1">
        <div className="container mx-auto">
          <div className="flex justify-between mx-3">
            <div>HALL OF FAME</div>
            <div>Home / Hall of Fame</div>
          </div>
        </div>
      </section>
      <section id="primary-category">
        <div className="container mx-auto my-10">
          <div className="mx-3">
            <div className="py-5">
              <h3 className="text-2xl font-bold font-primary text-primary">
                2022 National Public Speaking Competition Winners (Secondary)
              </h3>
            </div>
            <div className="flex flex-col space-y-10 lg:flex-row lg:space-x-10 lg:space-y-0">
              <div className="w-full lg:w-1/3">
                <iframe
                  src="https://www.youtube.com/embed/J9veKC1ulbA"
                  frameborder="0"
                  allowfullscreen
                  className="w-full rounded-lg aspect-video"
                ></iframe>
                <p className="py-5 text-lg font-text">
                  Champion, Sean Tey – “The Butterfly Effect.”
                </p>
              </div>
              <div className="w-full lg:max-w-1/3 lg:w-1/3">
                <iframe
                  src="https://www.youtube.com/embed/5ZAym9pv2Hs"
                  frameborder="0"
                  allowfullscreen
                  className="w-full rounded-lg aspect-video"
                ></iframe>
                <p className="py-5 text-lg font-text">
                  2nd Place Winner, Harjas Singh Sidhu – “Conquering The World”
                </p>
              </div>
              <div className="w-full lg:max-w-1/3 lg:w-1/3">
                <iframe
                  src="https://www.youtube.com/embed/lhpBhMrvIn8"
                  frameborder="0"
                  allowfullscreen
                  className="w-full rounded-lg aspect-video"
                ></iframe>
                <p className="py-5 text-lg font-text">
                  3rd Place Winner, Noelle Chong – “Does Money Truly Make Us
                  Happy?”
                </p>
              </div>
            </div>
            <div className="flex flex-col mt-10 space-y-10 lg:flex-row lg:space-x-10 lg:space-y-0">
              <div className="w-full lg:w-1/3">
                <iframe
                  src="https://www.youtube.com/embed/wr73GxPxdzY"
                  frameborder="0"
                  allowfullscreen
                  className="w-full rounded-lg aspect-video"
                ></iframe>
                <p className="py-5 text-lg font-text">
                  Champion, Sean Tey – “The Butterfly Effect.”
                </p>
              </div>
              <div className="w-full lg:max-w-1/3 lg:w-1/3">
                <iframe
                  src="https://www.youtube.com/embed/BbgF1KlOTc0"
                  frameborder="0"
                  allowfullscreen
                  className="w-full rounded-lg aspect-video"
                ></iframe>
                <p className="py-5 text-lg font-text">
                  2nd Place Winner, Harjas Singh Sidhu – “Conquering The World”
                </p>
              </div>
              <div className="w-full lg:max-w-1/3 lg:w-1/3"></div>
            </div>
          </div>
        </div>
      </section>
      <section id="primary-category" className="bg-gray-50">
        <div className="container mx-auto my-10">
          <div className="mx-3">
            <div className="py-5">
              <h3 className="text-2xl font-bold font-primary text-primary">
                2019 National Public Speaking Competition Winners (Primary)
              </h3>
            </div>
            <div className="flex flex-col space-y-10 lg:flex-row lg:space-x-10 lg:space-y-0">
              <div className="w-full lg:w-1/3">
                <iframe
                  src="https://www.youtube.com/embed/cqTdfF2DUJc"
                  frameborder="0"
                  allowfullscreen
                  className="w-full rounded-lg aspect-video"
                ></iframe>
                <p className="py-5 text-lg font-text">
                  Champion, Erica Lim Si En – “Extend Recess!”
                </p>
              </div>
              <div className="w-full lg:max-w-1/3 lg:w-1/3">
                <iframe
                  src="https://www.youtube.com/embed/dRA4WgWZHHo"
                  frameborder="0"
                  allowfullscreen
                  className="w-full rounded-lg aspect-video"
                ></iframe>
                <p className="py-5 text-lg font-text">
                  2nd Place Winner, Hrithik Ram Ganesh Kumar – “Why Parents
                  Should Allow Their Children To Play Video Games”
                </p>
              </div>
              <div className="w-full lg:max-w-1/3 lg:w-1/3">
                <iframe
                  src="https://www.youtube.com/embed/_A9eEBY9dG0"
                  frameborder="0"
                  allowfullscreen
                  className="w-full rounded-lg aspect-video"
                ></iframe>
                <p className="py-5 text-lg font-text">
                  3rd Place Winner, Chloe Lim – “Happy”
                </p>
              </div>
            </div>
            <div className="flex flex-col mt-10 space-y-10 lg:flex-row lg:space-x-10 lg:space-y-0">
              <div className="w-full lg:w-1/3">
                <iframe
                  src="https://www.youtube.com/embed/zbE_cSGrW_4"
                  frameborder="0"
                  allowfullscreen
                  className="w-full rounded-lg aspect-video"
                ></iframe>
                <p className="py-5 text-lg font-text">
                  4th Place Winner, Ng Yong Yi, Gregory – “Our Bicentennial, Or
                  Is It?”
                </p>
              </div>
              <div className="w-full lg:max-w-1/3 lg:w-1/3">
                <iframe
                  src="https://www.youtube.com/embed/3zTNCZots9U"
                  frameborder="0"
                  allowfullscreen
                  className="w-full rounded-lg aspect-video"
                ></iframe>
                <p className="py-5 text-lg font-text">
                  5th Place Winner, Amjathkhan Abeejith – “My Lifetime Evergreen
                  Experience”
                </p>
              </div>
              <div className="w-full lg:max-w-1/3 lg:w-1/3"></div>
            </div>
          </div>
        </div>
      </section>
      <section id="primary-category">
        <div className="container mx-auto my-10">
          <div className="mx-3">
            <div className="py-5">
              <h3 className="text-2xl font-bold font-primary text-primary">
                2019 National Public Speaking Competition Winners (Secondary)
              </h3>
            </div>
            <div className="flex flex-col space-y-10 lg:flex-row lg:space-x-10 lg:space-y-0">
              <div className="w-full lg:w-1/3">
                <iframe
                  src="https://www.youtube.com/embed/MpgnOwpJ1UU"
                  frameborder="0"
                  allowfullscreen
                  className="w-full rounded-lg aspect-video"
                ></iframe>
                <p className="py-5 text-lg font-text">
                  Top 5 Winning Speeches: Champion, Vishaleni- “You have what it
                  takes.”
                </p>
              </div>
              <div className="w-full lg:max-w-1/3 lg:w-1/3">
                <iframe
                  src="https://www.youtube.com/embed/YHvuMqDBCGM"
                  frameborder="0"
                  allowfullscreen
                  className="w-full rounded-lg aspect-video"
                ></iframe>
                <p className="py-5 text-lg font-text">
                  2nd Place Winner, Tvisha Bandhu – “Morsel”
                </p>
              </div>
              <div className="w-full lg:max-w-1/3 lg:w-1/3">
                <iframe
                  src="https://www.youtube.com/embed/ZcJvZ9sMKx0"
                  frameborder="0"
                  allowfullscreen
                  className="w-full rounded-lg aspect-video"
                ></iframe>
                <p className="py-5 text-lg font-text">
                  3rd Place Winner, Lo Pui Yee – “Define yourself”
                </p>
              </div>
            </div>
            <div className="flex flex-col mt-10 space-y-10 lg:flex-row lg:space-x-10 lg:space-y-0">
              <div className="w-full lg:w-1/3">
                <iframe
                  src="https://www.youtube.com/embed/0PCjyOEu5eo"
                  frameborder="0"
                  allowfullscreen
                  className="w-full rounded-lg aspect-video"
                ></iframe>
                <p className="py-5 text-lg font-text">
                  4th Place Winner, He Xing Hua – “Opinions”
                </p>
              </div>
              <div className="w-full lg:max-w-1/3 lg:w-1/3">
                <iframe
                  src="https://www.youtube.com/embed/cp4j1yG5HIc"
                  frameborder="0"
                  allowfullscreen
                  className="w-full rounded-lg aspect-video"
                ></iframe>
                <p className="py-5 text-lg font-text">
                  5th Place Winner, Ankushi – “Prisoners of the modern world”
                </p>
              </div>
              <div className="w-full lg:max-w-1/3 lg:w-1/3"></div>
            </div>
          </div>
        </div>
      </section>
      <section id="primary-category" className="bg-gray-50">
        <div className="container mx-auto my-10">
          <div className="mx-3">
            <div className="py-5">
              <h3 className="text-2xl font-bold font-primary text-primary">
                2018 National Public Speaking Competition Winners
              </h3>
            </div>
            <div className="flex flex-col space-y-10 lg:flex-row lg:space-x-10 lg:space-y-0">
              <div className="w-full lg:w-1/3">
                <iframe
                  src="https://www.youtube.com/embed/KkgC4fVPYig"
                  frameborder="0"
                  allowfullscreen
                  className="w-full rounded-lg aspect-video"
                ></iframe>
                <p className="py-5 text-lg font-text">
                  Champion, Chua Guang Rong – “Finding My Voice”
                </p>
              </div>
              <div className="w-full lg:max-w-1/3 lg:w-1/3">
                <iframe
                  src="https://www.youtube.com/embed/TJNYI6vOEpY"
                  frameborder="0"
                  allowfullscreen
                  className="w-full rounded-lg aspect-video"
                ></iframe>
                <p className="py-5 text-lg font-text">
                  2nd Place Winner, Tvisha Bandhu – “The Barrier Between Us”
                </p>
              </div>
              <div className="w-full lg:max-w-1/3 lg:w-1/3">
                <iframe
                  src="https://www.youtube.com/embed/wIvsLrCQXw8"
                  frameborder="0"
                  allowfullscreen
                  className="w-full rounded-lg aspect-video"
                ></iframe>
                <p className="py-5 text-lg font-text">
                  3rd Place Winner, Gabriel Chin – “Unhear What You Hear”
                </p>
              </div>
            </div>
            <div className="flex flex-col mt-10 space-y-10 lg:flex-row lg:space-x-10 lg:space-y-0">
              <div className="w-full lg:w-1/3">
                <iframe
                  src="https://www.youtube.com/embed/Ys9IkvU-6Oc"
                  frameborder="0"
                  allowfullscreen
                  className="w-full rounded-lg aspect-video"
                ></iframe>
                <p className="py-5 text-lg font-text">
                  4th Place Winner, Yap Yu Lun – “Let’s Live Again”
                </p>
              </div>
              <div className="w-full lg:max-w-1/3 lg:w-1/3">
                <iframe
                  src="https://www.youtube.com/embed/I8-NZcunxFw"
                  frameborder="0"
                  allowfullscreen
                  className="w-full rounded-lg aspect-video"
                ></iframe>
                <p className="py-5 text-lg font-text">
                  5th Place Winner, Benjamin Kok – “Crushed”
                </p>
              </div>
              <div className="w-full lg:max-w-1/3 lg:w-1/3"></div>
            </div>
          </div>
        </div>
      </section>
      <section id="primary-category">
        <div className="container mx-auto my-10">
          <div className="mx-3">
            <div className="py-5">
              <h3 className="text-2xl font-bold font-primary text-primary">
                2017 National Public Speaking Competition Winners
              </h3>
            </div>
            <div className="flex flex-col space-y-10 lg:flex-row lg:space-x-10 lg:space-y-0">
              <div className="w-full lg:w-1/3">
                <iframe
                  src="https://www.youtube.com/embed/XUsc1ljQAn4"
                  frameborder="0"
                  allowfullscreen
                  className="w-full rounded-lg aspect-video"
                ></iframe>
                <p className="py-5 text-lg font-text">
                  Champion’s winning speech
                </p>
              </div>
              <div className="w-full lg:max-w-1/3 lg:w-1/3">
                <iframe
                  src="https://www.youtube.com/embed/lMCmfaN1tvw"
                  frameborder="0"
                  allowfullscreen
                  className="w-full rounded-lg aspect-video"
                ></iframe>
                <p className="py-5 text-lg font-text">
                  2nd Place’s winning speech
                </p>
              </div>
              <div className="w-full lg:max-w-1/3 lg:w-1/3">
                <iframe
                  src="https://www.youtube.com/embed/wYZbQq3I0ek"
                  frameborder="0"
                  allowfullscreen
                  className="w-full rounded-lg aspect-video"
                ></iframe>
                <p className="py-5 text-lg font-text">
                  3rd Place’s winning speech
                </p>
              </div>
            </div>
            <div className="flex flex-col mt-10 space-y-10 lg:flex-row lg:space-x-10 lg:space-y-0">
              <div className="w-full lg:w-1/3">
                <iframe
                  src="https://www.youtube.com/embed/M2TouBKCbUM"
                  frameborder="0"
                  allowfullscreen
                  className="w-full rounded-lg aspect-video"
                ></iframe>
                <p className="py-5 text-lg font-text">
                  4th Place’s winning speech
                </p>
              </div>
              <div className="w-full lg:max-w-1/3 lg:w-1/3">
                <iframe
                  src="https://www.youtube.com/embed/-jYjP8sd-I4"
                  frameborder="0"
                  allowfullscreen
                  className="w-full rounded-lg aspect-video"
                ></iframe>
                <p className="py-5 text-lg font-text">
                  5th Place’s winning speech
                </p>
              </div>
              <div className="w-full lg:max-w-1/3 lg:w-1/3"></div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
