import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
const FormMailer = () => {
  const form = useRef();

  const [status, setStatus] = useState("");
  const [values, setValues] = useState({
    user_name: "",
    user_email: "",
    user_phone: "",
    message: "",
  });

  const handleChange = (e) => {
    setValues((values) => ({
      ...values,
      [e.target.name]: e.target.value,
    }));
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_vtahjq5",
        "template_oftwpzd",
        form.current,
        "MY5UOx72JeFC036mX"
      )
      .then(
        (result) => {
          setStatus("SUCCESS");
          setValues({
            user_name: "",
            user_email: "",
            user_phone: "",
            message: "",
          });
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <>
      {status && renderAlert()}
      <form
        className="flex flex-col p-5 space-y-5"
        ref={form}
        onSubmit={sendEmail}
      >
        <input
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          type="text"
          required
          onChange={handleChange}
          value={values.user_name}
          name="user_name"
          placeholder="ENTER NAME"
        />
        <input
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          type="email"
          required
          onChange={handleChange}
          name="user_email"
          value={values.user_email}
          placeholder="ENTER EMAIL"
        />
        <input
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          type="text"
          onChange={handleChange}
          name="user_phone"
          required
          value={values.user_phone}
          placeholder="ENTER TELEPHONE"
        />
        <textarea
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          name="message"
          onChange={handleChange}
          value={values.message}
          id=""
          cols="10"
          rows="4"
          placeholder="ENTER MESSAGE"
        ></textarea>
        <input
          type="submit"
          className="text-white bg-iconColor2 hover:bg-iconColor1 focus:ring-4 focus:outline-none focus:ring-iconColor1 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center cursor-pointer"
          value="Send"
        />
      </form>
    </>
  );
};

const renderAlert = () => (
  <div className="px-4 py-3 leading-normal text-blue-700 bg-blue-100 rounded mb-5">
    <p className=" font-bold">Your Message is Submitted Successfully</p>
  </div>
);

export default FormMailer;
