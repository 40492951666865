import { NavLink } from "react-router-dom";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Helmet } from "react-helmet";
import FormMailer from "../components/FormMailer";

export default function Contact() {
  return (
    <>
      <Helmet>
        <title>Contact | Public Speaking Academy</title>
        <meta
          name="description"
          content="Contact or visit one of our branches at Safra Tampines, Coronation Plaza or Hougang ActiveSG Support Centre. Led by top public speaker, Darren Tay."
        />
      </Helmet>

      <section id="breadcrumb" className="bg-[#F5F5FC] py-1 mx-2">
        <div className="container mx-auto">
          <div className="flex justify-between">
            <div>Data Protection Policy</div>
            <div>Home / Data Protection Policy</div>
          </div>
        </div>
      </section>

      <section id="latest-blog" className="mx-2 my-10">
        <div className="container mx-auto">
          <Tabs>
            <TabList>
              <Tab>Public Speaking Academy @Tampines</Tab>
              <Tab>Public Speaking Academy @Bukit Timah</Tab>
            </TabList>

            <TabPanel>
              <div>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15954.878822290595!2d103.941665!3d1.344602!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da3d158a01b709%3A0x87ef09c19bae6126!2sSAFRA%20Tampines!5e0!3m2!1sen!2sus!4v1680511802966!5m2!1sen!2sus"
                  width="100%"
                  height="450"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
                <div className="flex flex-col my-12 space-y-10 lg:flex-row lg:space-x-10 lg:space-y-0">
                  <div className="w-full lg:w-1/2">
                    <div className="p-5 border-2 border-gray-400 border-dashed rounded-2xl">
                      <div className="my-2">
                        <p className="inline px-2 py-1 text-xl font-bold text-white bg-purple-500 border rounded-lg font-primary">
                          OUR ADDRESS
                        </p>
                      </div>
                      <div className="flex flex-row items-center py-1 space-x-5">
                        <div className="py-2 text-lg font-text text-textColor">
                          <p className="">
                            <span class="material-symbols-rounded text-red-500 border-2 border-red-500 p-3 rounded-full text-6xl">
                              pin_drop
                            </span>
                          </p>
                        </div>
                        <div className="text-lg font-text text-textColor">
                          Public Speaking Academy@Tampines
                          <br />
                          1A Tampines Street 92
                          <br />
                          #01-13B (Safra Tampines)
                          <br />
                          Singapore-528882.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full lg:w-1/2">
                    <div className="p-5 border-2 border-gray-400 border-dashed rounded-2xl">
                      <div className="my-2">
                        <p className="inline px-2 py-1 text-xl font-bold text-white bg-purple-500 border rounded-lg font-primary">
                          CONTACT DETAILS
                        </p>
                      </div>
                      <div className="flex flex-row items-center py-2 text-lg font-text text-textColor">
                        <p className="">
                          <span class="material-symbols-rounded text-red-500 border-2 border-red-500 p-2 rounded-full">
                            call
                          </span>
                        </p>
                        <p className="px-2">64434049</p>
                      </div>
                      <div className="flex flex-row items-center py-2 text-lg font-text text-textColor">
                        <p className="">
                          <span class="material-symbols-rounded text-red-500 border-2 border-red-500 p-2 rounded-full">
                            mail
                          </span>
                        </p>
                        <p className="px-2">
                          support@publicspeakingacademy.com.sg
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col my-20 space-y-20 md:space-y-0 md:space-x-5 md:flex-row">
                  <div className="w-full md:w-1/3">
                    <div className="bg-gray-100 rounded-md md:min-h-[220px]">
                      <div className="text-center">
                        <span class="material-symbols-rounded text-4xl text-primary border-2 border-gray-200 p-4 rounded-full bg-white w-20 h-20 -mt-10">
                          directions_bus
                        </span>
                      </div>
                      <div className="p-3">
                        <p>
                          From Tampines Bus Interchange, please take Bus number
                          8, 18, 23, 28, 65 and alight at Bus-stop B75059 (which
                          is a 1-min walk from Safra Tampines).
                          <br />
                          Bus-stop B75059 also serves Bus number 5, 15, 21, 59,
                          67, 168, 513, 518.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="w-full md:w-1/3">
                    <div className="bg-gray-100 rounded-md md:min-h-[220px]">
                      <div className="text-center">
                        <span class="material-symbols-rounded text-4xl text-primary border-2 border-gray-200 p-4 rounded-full bg-white w-20 h-20 -mt-10">
                          directions_subway
                        </span>
                      </div>
                      <div className="p-3">
                        <p>
                          The nearest MRT station is Tampines MRT Station (EW2).
                          Walk over to the Tampines Bus Interchange (which is
                          just next to the MRT station) and take the buses as
                          stated above under the Bus category.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="w-full md:w-1/3">
                    <div className="bg-gray-100 rounded-md md:min-h-[220px]">
                      <div className="text-center">
                        <span class="material-symbols-rounded text-4xl text-primary border-2 border-gray-200 p-4 rounded-full bg-white w-20 h-20 -mt-10">
                          directions_car
                        </span>
                      </div>
                      <div className="p-3">
                        <p>
                          There is a multi-story carpark and open-air carpark
                          which serve SAFRA Tampines. Please refer to SAFRA
                          Tampines website for the carpark rates.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7977.506443089583!2d103.810301!3d1.323932!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da1a062918410b%3A0xe93b4fb10fa566a2!2sCoronation%20Plaza!5e0!3m2!1sen!2sus!4v1680517851973!5m2!1sen!2sus"
                  width="100%"
                  height="450"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
                <div className="flex flex-col my-12 space-y-10 lg:flex-row lg:space-x-10 lg:space-y-0">
                  <div className="w-full lg:w-1/2">
                    <div className="p-5 border-2 border-gray-400 border-dashed rounded-2xl">
                      <div className="my-2">
                        <p className="inline px-2 py-1 text-xl font-bold text-white bg-purple-500 border rounded-lg font-primary">
                          OUR ADDRESS
                        </p>
                      </div>
                      <div className="flex flex-row items-center py-1 space-x-5">
                        <div className="py-2 text-lg font-text text-textColor">
                          <p className="">
                            <span class="material-symbols-rounded text-red-500 border-2 border-red-500 p-3 rounded-full text-6xl">
                              pin_drop
                            </span>
                          </p>
                        </div>
                        <div className="text-lg font-text text-textColor">
                          Public Speaking Academy@Bukit Timah
                          <br />
                          Coronation Shopping Plaza #02-27,
                          <br />
                          587 Bukit Timah Road,
                          <br />
                          Singapore 269707.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full lg:w-1/2">
                    <div className="p-5 border-2 border-gray-400 border-dashed rounded-2xl">
                      <div className="my-2">
                        <p className="inline px-2 py-1 text-xl font-bold text-white bg-purple-500 border rounded-lg font-primary">
                          CONTACT DETAILS
                        </p>
                      </div>
                      <div className="flex flex-row items-center py-2 text-lg font-text text-textColor">
                        <p className="">
                          <span class="material-symbols-rounded text-red-500 border-2 border-red-500 p-2 rounded-full">
                            call
                          </span>
                        </p>
                        <p className="px-2">64434049</p>
                      </div>
                      <div className="flex flex-row items-center py-2 text-lg font-text text-textColor">
                        <p className="">
                          <span class="material-symbols-rounded text-red-500 border-2 border-red-500 p-2 rounded-full">
                            mail
                          </span>
                        </p>
                        <p className="px-2">
                          support@publicspeakingacademy.com.sg
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col my-20 space-y-20 md:space-y-0 md:space-x-5 md:flex-row">
                  <div className="w-full md:w-1/3">
                    <div className="bg-gray-100 rounded-md md:min-h-[220px]">
                      <div className="text-center">
                        <span class="material-symbols-rounded text-4xl text-primary border-2 border-gray-200 p-4 rounded-full bg-white w-20 h-20 -mt-10">
                          directions_bus
                        </span>
                      </div>
                      <div className="p-3">
                        <p>
                          Bus Stop #: 41041
                          <br />
                          Bus Service #: 66, 67, 74, 151, 154, 156, 157, 170,
                          171, 174, 852, 961, and 645
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="w-full md:w-1/3">
                    <div className="bg-gray-100 rounded-md md:min-h-[220px]">
                      <div className="text-center">
                        <span class="material-symbols-rounded text-4xl text-primary border-2 border-gray-200 p-4 rounded-full bg-white w-20 h-20 -mt-10">
                          directions_subway
                        </span>
                      </div>
                      <div className="p-3">
                        <p>
                          Tan Kah Kee MRT Station: 450 meters (5 mins walk)
                          <br />
                          Botanic Gardens MRT Station: 600 meters (8 mins walk)
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="w-full md:w-1/3">
                    <div className="bg-gray-100 rounded-md md:min-h-[220px]">
                      <div className="text-center">
                        <span class="material-symbols-rounded text-4xl text-primary border-2 border-gray-200 p-4 rounded-full bg-white w-20 h-20 -mt-10">
                          directions_car
                        </span>
                      </div>
                      <div className="p-3">
                        <p>
                          There is a multi-story carpark and open-air carpark
                          which serve Coronation Shopping Plaza.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </section>
      <section className="lg:mx-2">
        <div className="container mx-auto">
          <div className="px-5 py-10 rounded-t-lg bg-orange-50">
            <h3 className="pb-2 text-2xl font-bold text-center font-primary text-primary">
              Drop us a Quick Mail
            </h3>
            <div className="w-full md:w-1/2 mx-auto">
              <FormMailer />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
