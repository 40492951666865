import Footer from "../components/Footer";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

export default function Post() {
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const perPage = 9;
  useEffect(() => {
    let url = `${process.env.REACT_APP_API_ROOT}/posts?per_page=${perPage}&page=${currentPage}`;
    axios.get(url).then((res) => {
      const { data, headers } = res;
      //console.log("headers", headers["x-wp-totalpages"]);
      setTotalPages(Number(headers["x-wp-totalpages"]));
      setPosts(data);
    });
  }, [currentPage]);
  //console.log("posts", posts);
  return (
    <>
      <Helmet>
        <title>Blog Archives | Public Speaking Academy</title>
        <meta
          name="description"
          content="Find out the best blogs on Public Speaking. Led by top public speaker, Darren Tay."
        />
      </Helmet>

      <section id="breadcrumb" className="bg-[#F5F5FC] py-1">
        <div className="container mx-auto">
          <div className="flex justify-between mx-3">
            <div>OUR Blog</div>
            <div>Home / Blog</div>
          </div>
        </div>
      </section>

      <section id="latest-blog">
        <div className="container mx-auto">
          <div className="pt-24">
            <h1 className="text-5xl font-bold text-center uppercase font-primary text-primary">
              OUR LATEST BLOG
            </h1>
            <p className="my-5 text-2xl text-center text-textColor font-text">
              Read Our Latest Blogs and Posts
            </p>
            <div>
              <svg
                className="mx-auto"
                width="37"
                height="19"
                viewBox="0 0 37 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.965575 0.0283205L36.9214 0.0283203L18.9435 18.0062L0.965575 0.0283205Z"
                  fill="#8598AF"
                />
              </svg>
            </div>
          </div>
          <div className="flex flex-row flex-wrap">
            {posts.map((post) => {
              //console.log(post.slug.rendered);
              const [date, time] = post.date.split("T");
              const [year, month, day] = date.split("-");

              return (
                <div key={post.id} className="w-full lg:w-1/3 p-5">
                  <div className=" bg-slate-50">
                    <LazyLoadImage
                      src={post.featured_src["0"]}
                      effect="blur"
                      alt=""
                      className="w-full h-auto"
                    />
                    <div className="p-5">
                      <h2
                        className="my-6 text-xl font-medium text-purple-700 font-primary"
                        dangerouslySetInnerHTML={{
                          __html: post.title.rendered,
                        }}
                      ></h2>
                      <p
                        className="my-4 text-sm text-gray-500 font-sans"
                        dangerouslySetInnerHTML={{
                          __html: post.excerpt.rendered,
                        }}
                      ></p>
                      <div key={post.id} className="py-6">
                        <NavLink
                          className="px-4 py-2 font-bold text-purple-700 uppercase border-2 border-purple-700 border-solid rounded-lg hover:bg-purple-700 hover:text-white"
                          to={`/${year}/${month}/${day}/${post.slug}`}
                        >
                          Read More
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="w-full lg:w-1/2 xl:w-1/3 mx-auto">
            <div className="flex flex-row justify-between flex-wrap align-middle lg:gap-5 my-20 mx-5">
              <button
                className=" btn-primary p-2 w-28 disabled:opacity-50"
                disabled={currentPage === 1}
                onClick={() => setCurrentPage(currentPage - 1)}
              >
                Previous
              </button>
              Page {currentPage} of {totalPages}
              <button
                className=" btn-primary p-2 w-28 disabled:opacity-50"
                disabled={currentPage === totalPages}
                onClick={() => setCurrentPage(currentPage + 1)}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
