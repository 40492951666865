import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import { Helmet } from "react-helmet";

export default function CorporateBusinessTraining() {
  return (
    <>
      <Helmet>
        <title>
          Corporate Public Speaking Training Workshop | Public Speaking Academy
        </title>
        <meta
          name="description"
          content="Corporate Public Speaking Training Workshop led by top public speaker, Darren Tay."
        />
      </Helmet>

      <section id="breadcrumb" className="bg-[#F5F5FC] py-1">
        <div className="container mx-auto">
          <div className="flex flex-row justify-between mx-3">
            <div>CORPORATE / BUSINESS TRAINING</div>
            <div>
              Home / Corporate / School Training / Corporate/Business Training
            </div>
          </div>
        </div>
      </section>

      <section id="block-1" className="mx-3">
        <div class="container mx-auto py-20">
          <div class="flex flex-col lg:flex-row pt-5 space-x-10">
            <div class="w-full lg:w-2/3">
              <h1 class="font-primary text-2xl font-bold text-primary uppercase">
                Public Speaking Corporate Training & Group Training (for
                Businesses, Organizations, and Workplaces)
              </h1>
              <h2 class="font-primary text-xl font-bold text-primary uppercase py-5">
                Customised & Tailored Corporate Training to Inspire, Motivate,
                and Upskill Your Team, Company, or Business with Public Speaking
                / Effective Communication Skills
              </h2>
              <div>
                <img
                  src="/images/public-speaking-course-for-adults-public-speaking-courses-for-adults-public-speaking-for-adults-1-3-1.png"
                  alt=""
                />
                <div className="p-5 my-5 bg-orange-200 rounded-md ">
                  <svg
                    width="48"
                    height="34"
                    viewBox="0 0 48 34"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M44.0002 33.6666H34.0002L27.3335 20.3333V0.333313H47.3335V20.3333H37.3335L44.0002 33.6666ZM17.3335 33.6666H7.33349L0.666824 20.3333V0.333313H20.6668V20.3333H10.6668L17.3335 33.6666Z"
                      fill="#F17732"
                    />
                  </svg>
                  <h3 class="font-primary text-2xl font-bold text-primary text-center">
                    To upskill or not to upskill is no longer the question
                  </h3>
                  <p className="mb-10 text-lg text-center">
                    PWC, 23rd Annual Global CEO Survey
                  </p>
                </div>

                <p class="py-2 font-text text-lg font-bold">
                  “I want my team to be more confident in their networking,
                  meetings, and presentations.”
                </p>
                <p class="py-2 font-text text-lg">
                  At the workplace, your team members, colleagues, and staff are
                  the primary drivers of the company’s success. They are the
                  resources the modern company invests in to push for growth.
                  According to PWC’s 23rd Annual Global CEO Survey, the majority
                  of CEOs surveyed in 2019 agreed that “significant
                  retraining/upskilling was the most important way to close a
                  potential skills gap in their organisation”. As they framed
                  succinctly, to “upskill or not to upskill is no longer the
                  question”.
                </p>
                <p class="py-2 font-text text-lg">
                  <b>We want to help you achieve that success.</b> Public
                  speaking, communication or even persuasion skills are no
                  longer trivial “soft” skills. They are now a coveted skill-set
                  in the modern home, workplace, and world.
                </p>

                <h3 class="py-5 font-text text-2xl font-bold italic">
                  So, why the Public Speaking Academy?
                </h3>
                <p class="py-2 font-text text-lg">
                  The process of nurturing public speaking confidence can be a
                  daunting path. With Public Speaking Academy, we will bring you
                  expert and experienced communication training, driven by our
                  passion to help you present your voice to a wider audience and
                  dominate the business landscape. From 2009, and building on
                  our Master Trainer’s experience and methodology, we have been
                  conducting public speaking training in ensuring that you and
                  your team remain relevant, competitive and nimble.
                </p>
                <img
                  className="my-10"
                  src="/images/public-speaking-course-for-adults-public-speaking-courses-for-adults-public-speaking-for-adults-3.jpg"
                  alt=""
                />

                <p className="text-lg font-text">
                  With the Public Speaking Academy as your choice for a
                  communications corporate training provider, you will get:
                </p>
                <ul className="p-5 list-disc list-outside">
                  <li className="text-lg font-text">
                    <b>World Champion Experience:</b> Our programmes are helmed
                    by our quality and professional training faculty members,
                    each in high demand for expertise, accolades, and experience
                    in public speaking. Our training team is led by our Master
                    Trainer/Founder, Darren Tay, the World Champion of Public
                    Speaking in 2016, leveraging his experience in
                    speaking/training more than 250,000 individuals in effective
                    communication skills to utilise in the workplace and the
                    world.
                  </li>
                  <li className="text-lg font-text">
                    <b>Extensive Experience:</b> Our extensive experience in
                    public speaking stretches from 2009, growing to a total of 3
                    branches around Singapore. We have served SMEs, corporate
                    organisations, training companies, schools, and even
                    start-up incubators in customising public
                    speaking/communication solutions for their unique needs.
                  </li>
                  <li className="text-lg font-text">
                    <b>Customised Experience:</b> We draw from our extensive
                    syllabus and knowledge base to design a customised learning
                    experience that best fits your goals and budget – be it a
                    keynote, a masterclass, or a full public speaking
                    workshop/boot camp. Whether you are looking at developing
                    general public speaking confidence to specialised
                    communication corporate training (e.g., business
                    presentations, negotiation), we will work closely with you
                    to design a programme tailored to your company’s unique
                    learning and business needs.
                  </li>
                </ul>

                <h3 class="py-5 font-text text-2xl font-bold italic">
                  What is the Benefit for You & Your Company?
                </h3>
                <p className="text-lg font-text">
                  While every organisation/company carries a unique need, we are
                  versatile enough to reach into our knowledge and experience
                  bank to fill a gap you may have. We adopt a reasoned,
                  researched, and relevant approach in designing our programmes
                  that straddle a careful balance between (i) engaging theory
                  sharing (e.g., presentations on technique), and (ii) evaluated
                  practice sessions (e.g., practice presentations with
                  feedback). These are some examples of the programmes we have
                  conducted before:
                </p>
                <ul className="p-5 list-disc list-outside">
                  <li className="text-lg font-text">
                    <b>Team-Building Objective:</b> We have arranged public
                    speaking corporate training courses focusing on
                    story-crafting, brand-building, and general public speaking
                    confidence, to provide our participants the opportunity to
                    share their signature stories, inspire their teammates, and
                    practise their interpersonal communication skills in a safe
                    and constructive environment.
                  </li>
                  <li className="text-lg font-text">
                    <b>Upskill with Relevance:</b> We have listened to our
                    clients’ needs and identify precise and relevant skills to
                    help their team grow as competent, confident, and creative
                    communicators (e.g., impromptu speaking, pitching/sales
                    presentation skills, or online presentation techniques).
                    Invest in your team’s success today!
                  </li>
                  <li className="text-lg font-text">
                    <b>Leadership Potential:</b> Effective communication skills,
                    such as persuasion or conflict management, remain one of the
                    core leadership skills in a corporate setting. Whether it is
                    for your growth as part of the company’s management or to
                    ensure quality leadership development/renewal in your team,
                    our customised public speaking corporate training programmes
                    will help you reach that leadership potential!
                  </li>
                </ul>

                <p class="py-2 font-text text-lg">
                  We have covered a wide variety of topics, which include:
                </p>
                <p className="pt-5 pb-5 text-3xl font-bold text-center">
                  Topics Covered:
                </p>
                <img className="my-5" src="/images/topics-covered.jpg" alt="" />
                <p className="pt-5 pb-5 text-2xl italic font-bold font-primary">
                  Alright, I am in! What Now?
                </p>
                <p className="text-lg font-text">
                  We are excited to begin this journey with you to achieve
                  public speaking success! First, we would love to know more
                  about you and your team’s communication needs - feel free to
                  reach out to us through our Query Form to start the
                  conversation and curate your organization’s public speaking
                  training.
                </p>
                <p className="py-3 text-lg font-text">
                  We will be there with you as we design the best-fit programme
                  to develop your team into highly effective public speakers at
                  work, empowered with charisma, influence, and impact!
                </p>
                <p className="py-3 text-lg font-bold font-text">
                  In reaching out to us, it would be helpful to share these
                  preliminary information to help us understand how best to
                  start:
                </p>

                <ul className="p-5 list-disc list-outside">
                  <li className="text-lg font-text">
                    Total number of participants
                  </li>
                  <li className="text-lg font-text">
                    Total duration (estimated)
                  </li>
                  <li className="text-lg font-text">
                    Nature of programme (e.g., 1-day, masterclasses, regular
                    sessions)
                  </li>
                  <li className="text-lg font-text">
                    Total number of trainers required
                  </li>
                  <li className="text-lg font-text">
                    Total duration of training (including time distribution,
                    e.g., 18 hours of instruction, over 2 days or 4 days)
                  </li>
                  <li className="text-lg font-text">
                    Summary of the learning objectives
                  </li>
                  <li className="text-lg font-text">
                    Preferred training venue (at one of our learning centres or
                    on-site)
                  </li>
                  <li className="text-lg font-text">
                    Estimated budget for the assignment
                  </li>
                </ul>
              </div>
            </div>
            <div className="hidden lg:inline w-1/3 px-5 pt-5 mb-10 border border-dashed rounded-lg">
              <Sidebar />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
