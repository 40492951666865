import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

export default function SeniorTrainers() {
  return (
    <>
      <Helmet>
        <title>Senior Trainers & Trainers | Public Speaking Academy</title>
        <meta
          name="description"
          content="Find out why we are the preferred choice for effective communication excellence and public speaking course for kids in Singapore. Led by top public speaker, Darren Tay."
        />
      </Helmet>

      <section id="breadcrumb" className="bg-[#F5F5FC] py-1">
        <div className="container mx-auto">
          <div className="flex justify-between">
            <div>Senior Trainers</div>
            <div>Home / Our Faculty / Senior Trainers</div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="lg:mx-20 rounded-3xl">
          <div className="container py-10 mx-auto">
            <div className="px-1 pt-5 lg:pt-10">
              <h1 className="text-4xl font-bold text-center lg:text-5xl font-primary text-primary">
                Meet Our Training Team!
              </h1>

              <hr className="my-4 mx-auto w-48 h-1 bg-[#8598AF] rounded border-0" />
              <div>
                <svg
                  className="mx-auto"
                  width="37"
                  height="19"
                  viewBox="0 0 37 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.965575 0.0283205L36.9214 0.0283203L18.9435 18.0062L0.965575 0.0283205Z"
                    fill="#8598AF"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="trainer-1">
        <div className="mx-5 rounded-3xl md:mx-20">
          <div className="container mx-auto">
            <div className="pt-14">
              <div className="flex flex-col items-center border border-gray-400 border-dashed lg:space-x-10 lg:flex-row rounded-2xl">
                <div className="w-full">
                  <div className="p-5">
                    <img src="/images/trainer-1.jpg" alt="" />
                  </div>
                </div>
                <div className="w-full">
                  <div className="p-5">
                    <div className="">
                      <h2 className="py-4 text-4xl font-bold text-center text-primary font-primary">
                        Zulhafni Zulkeflee
                      </h2>
                      <h3 className="text-xl font-bold text-center text-primary font-primary">
                        Senior Trainer
                      </h3>
                    </div>
                    <p className="py-4 text-xl font-text text-textColor">
                      A lawyer/negotiator by training, and formerly a Deputy
                      Public Prosecutor with the Attorney-General’s Chambers,
                      after graduating from the National University of
                      Singapore, Faculty of Law. As a champion speaker and
                      experienced educator, Zulhafni believes in the importance
                      of developing valuable communication skills on stage and
                      in life. He is also an ACTA-certified coach, and an SMC
                      accredited Mediator.
                    </p>
                    <div className="py-4 text-center">
                      <a
                        href="#zulhafni"
                        className="px-8 py-2 text-xl font-bold text-white rounded-md bg-btnColor"
                      >
                        READ MORE
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pb-10"></div>
        </div>
      </section>

      <section id="trainer-3">
        <div className="mx-5 rounded-3xl md:mx-20">
          <div className="container mx-auto">
            <div className="pt-14">
              <div className="flex flex-col items-center border border-gray-400 border-dashed lg:space-x-10 lg:flex-row rounded-2xl">
                <div className="w-full">
                  <div className="p-5">
                    <div className="">
                      <h2 className="py-4 text-4xl font-bold text-center text-primary font-primary">
                        Dr. Merrell Lim
                      </h2>
                      <h3 className="text-xl font-bold text-center text-primary font-primary">
                        Vice Principal & Senior Trainer
                      </h3>
                    </div>
                    <p className="py-4 text-xl font-text text-textColor">
                      Dr Merrell is a professional speaker and holds a Doctorate
                      in Pharmacy (PharmD) from Midwestern University, USA. As
                      an international speaker who has spoken and trained in
                      more than 10 different countries, Dr Lim has over a decade
                      of speaking and training experience, with keen expertise
                      in technical presentations
                    </p>
                    <div className="py-4 text-center">
                      <a
                        href="#merrell"
                        className="px-8 py-2 text-xl font-bold text-white rounded-md bg-btnColor"
                      >
                        READ MORE
                      </a>
                    </div>
                  </div>
                </div>
                <div className="w-full">
                  <div className="p-5">
                    <img src="/images/merrell.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pb-10"></div>
        </div>
      </section>

      <section id="trainer-5">
        <div className="mx-5 rounded-3xl md:mx-20">
          <div className="container mx-auto">
            <div className="pt-14">
              <div className="flex flex-col items-center border border-gray-400 border-dashed lg:space-x-10 lg:flex-row rounded-2xl">
                <div className="w-full">
                  <div className="p-5">
                    <div className="">
                      <h2 className="py-4 text-4xl font-bold text-center text-primary font-primary">
                        Mugan S/O Tayalan
                      </h2>
                      <h3 className="text-xl font-bold text-center text-primary font-primary">
                        Senior Trainer
                      </h3>
                    </div>
                    <p className="py-4 text-xl font-text text-textColor">
                      Mugan builds on his wealth of knowledge and experience in
                      youth development (as a coach/trainer for the past 12
                      years) and a trained, corporate adventure learning
                      facilitator for the past 6 years. He is an ACTA-certified
                      trainer and is also certified to administer the D.I.S.C.
                      personality profiling instrument.
                    </p>
                    <div className="py-4 text-center">
                      <a
                        href="#mugan"
                        className="px-8 py-2 text-xl font-bold text-white rounded-md bg-btnColor"
                      >
                        READ MORE
                      </a>
                    </div>
                  </div>
                </div>
                <div className="w-full">
                  <div className="p-5">
                    <img src="/images/mugan.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pb-10"></div>
        </div>
      </section>

      <section id="trainer-6">
        <div className="mx-5 rounded-3xl md:mx-20">
          <div className="container mx-auto">
            <div className="pt-14">
              <div className="flex flex-col-reverse items-center border border-gray-400 border-dashed lg:space-x-10 lg:flex-row rounded-2xl">
                <div className="w-full">
                  <div className="p-5">
                    <img src="/images/imran.png" alt="" />
                  </div>
                </div>
                <div className="w-full">
                  <div className="p-5">
                    <div className="">
                      <h2 className="py-4 text-4xl font-bold text-center text-primary font-primary">
                        Imran Tahir
                      </h2>
                      <h3 className="text-xl font-bold text-center text-primary font-primary">
                        Senior Trainer
                      </h3>
                    </div>
                    <p className="py-4 text-xl font-text text-textColor">
                      Having facilitated multiple conversations with numerous
                      teams across different industries to help them achieve
                      better clarity in moving towards their goals, Imran
                      believes that the key ingredient to unlocking success in
                      any endeavour is the ability to communicate and articulate
                      ideas with passion and purpose. Imran is also the
                      contributing author of the publication, “Reluctant
                      Networking - Bold Step to Connect”
                    </p>
                    <div className="py-4 text-center">
                      <a
                        href="#imran"
                        className="px-8 py-2 text-xl font-bold text-white rounded-md bg-btnColor"
                      >
                        READ MORE
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pb-10"></div>
        </div>
      </section>
      <section id="trainer-7">
        <div className="mx-5 rounded-3xl md:mx-20">
          <div className="container mx-auto">
            <div className="pt-14">
              <div className="flex flex-col items-center border border-gray-400 border-dashed lg:space-x-10 lg:flex-row rounded-2xl">
                <div className="w-full">
                  <div className="p-5">
                    <div className="">
                      <h2 className="py-4 text-4xl font-bold text-center text-primary font-primary">
                        Veronica Chan
                      </h2>
                      <h3 className="text-xl font-bold text-center text-primary font-primary">
                        Senior Trainer
                      </h3>
                    </div>
                    <p className="py-4 text-xl font-text text-textColor">
                      Graduated from Griffith University, Queensland,
                      Australia(2012), specializing in theatre arts, speech &
                      drama.
                    </p>
                    <ul className="list-disc list-inside">
                      <li className="text-xl font-text text-textColor">
                        10 years of private tuition and school teaching
                        experience
                      </li>
                      <li className="text-xl font-text text-textColor">
                        Dynamic and Energetic Educator
                      </li>
                      <li className="text-xl font-text text-textColor">
                        Taught at multiple Primary Schools in Singapore
                      </li>
                    </ul>
                    <p className="py-4 text-xl font-text text-textColor">
                      Ms Veronica Chan is a professional speaker, trainer and
                      media personality with 10 years of private tuition and
                      school teaching experience.
                      <br />
                      Ms Veronica has conducted numerous drama workshops, public
                      speaking classes and speech & drama classes in government
                      schools and private learning institutions. She has also
                      trained students from drama clubs for the Singapore Youth
                      Festival (SYF) drama competitions. As a dynamic, energetic
                      and experienced educator, Ms Veronica believes that
                      students learn best when they enjoy the learning process.
                      Hence, making learning fun for the students, while
                      maintaining the academic rigour and quality of training.
                    </p>
                  </div>
                </div>
                <div className="w-full">
                  <div className="p-5">
                    <img src="/images/veronica.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pb-10"></div>
        </div>
      </section>
      <section id="trainer-8">
        <div className="mx-5 rounded-3xl md:mx-20">
          <div className="container mx-auto">
            <div className="pt-14">
              <div className="flex flex-col-reverse items-center border border-gray-400 border-dashed lg:space-x-10 lg:flex-row rounded-2xl">
                <div className="w-full">
                  <div className="p-5">
                    <img src="/images/jeremy.jpg" alt="" />
                  </div>
                </div>
                <div className="w-full">
                  <div className="p-5">
                    <div className="">
                      <h2 className="py-4 text-4xl font-bold text-center text-primary font-primary">
                        Mr. Jeremy Lee
                      </h2>
                      <h3 className="text-xl font-bold text-center text-primary font-primary">
                        Senior Trainer
                      </h3>
                    </div>
                    <p className="py-2 text-xl font-text text-textColor">
                      Mr Jeremy Lee is a passionate trainer who enjoys teaching
                      and coaching kids as well as teenagers. With more than 5
                      years of teaching and coaching experience, Mr Jeremy Lee
                      received training directly from Founder of PSA and First
                      Singaporean World Champion of Public Speaking, Mr Darren
                      Tay.
                    </p>
                    <p className="py-2 text-xl font-text text-textColor">
                      Currently an undergraduate with NTU (Nanyang Technological
                      University), Mr Jeremy Lee enjoys reading and researching
                      into the art of effective communication. He believes that
                      communicating with kindness is the key to building
                      relationships and overcoming conflicts.
                    </p>
                    <p className="py-2 text-xl font-text text-textColor">
                      Mr Jeremy Lee is a registered trainer with the Public
                      Speaking Academy.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pb-10"></div>
        </div>
      </section>
      <section id="trainer-9">
        <div className="mx-5 rounded-3xl md:mx-20">
          <div className="container mx-auto">
            <div className="pt-14">
              <div className="flex flex-col items-center border border-gray-400 border-dashed lg:space-x-10 lg:flex-row rounded-2xl">
                <div className="w-full">
                  <div className="p-5">
                    <div className="">
                      <h2 className="py-4 text-4xl font-bold text-center text-primary font-primary">
                        Mr. Tan Jing En
                      </h2>
                      <h3 className="text-xl font-bold text-center text-primary font-primary">
                        Senior Trainer
                      </h3>
                    </div>
                    <p className="py-2 text-xl font-text text-textColor">
                      Jing En is an NTU scholar and author of the public
                      speaking book “Backstage with a Crazy Eloquent Asian”.
                    </p>
                    <p className="py-2 text-xl font-text text-textColor">
                      Jing En believes in employing engaging strategies and
                      enriching activities to help students learn in a conducive
                      environment. Jing En has been working very closely with
                      our Founder and First Singaporean of Public Speaking Mr.
                      Darren Tay, to devise engaging in-class activities for the
                      Speech Excellence Programme (SEP) and S’Peak Performance
                      Programme (SPP).
                    </p>
                    <p className="py-2 text-xl font-text text-textColor">
                      With Jing En’s experience and passion for public speaking,
                      we believe that your child/ children will enjoy and
                      benefit from his engaging style of training and
                      professional evaluation and training.
                    </p>
                  </div>
                </div>
                <div className="w-full">
                  <div className="p-5">
                    <img src="/images/jing-en.jpg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pb-10"></div>
        </div>
      </section>

      <section id="trainer-2">
        <div className="mx-5 rounded-3xl md:mx-20">
          <div className="container mx-auto">
            <div className="pt-14">
              <div className="flex flex-col items-center border border-gray-400 border-dashed lg:space-x-10 lg:flex-row rounded-2xl">
                <div className="w-full">
                  <div className="p-5">
                    <img src="/images/Terence-Lee.png" alt="" />
                  </div>
                </div>
                <div className="w-full">
                  <div className="p-5">
                    <div className="">
                      <h2 className="py-4 text-4xl font-bold text-center text-primary font-primary">
                        Terence Lee
                      </h2>
                      <h3 className="text-xl font-bold text-center text-primary font-primary">
                        Senior Trainer
                      </h3>
                    </div>
                    <p className="py-4 text-xl font-text text-textColor">
                      Terence Lee is a passionate and experienced trainer.
                      Trained personally by the First Singaporean World Champion
                      of Public Speaking, Darren Tay, Terence has accumulated
                      more than 5 years of training experience. He enjoys
                      inspiring kids and teenagers to step outside of their
                      comfort zone and emerge as more confident speakers.
                      Terence is a registered trainer with MOE to teach public
                      speaking at the Public Speaking Academy (Singapore).
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pb-10"></div>
        </div>
      </section>

      <section id="trainer-2">
        <div className="mx-5 rounded-3xl md:mx-20">
          <div className="container mx-auto">
            <div className="pt-14">
              <div className="flex flex-col-reverse items-center border border-gray-400 border-dashed lg:space-x-10 lg:flex-row rounded-2xl">
                <div className="w-full">
                  <div className="p-5">
                    <div className="">
                      <h2 className="py-4 text-4xl font-bold text-center text-primary font-primary">
                        Sanjana Kamdar
                      </h2>
                      <h3 className="text-xl font-bold text-center text-primary font-primary">
                        Senior Trainer
                      </h3>
                    </div>
                    <p className="py-4 text-xl font-text text-textColor">
                      Sanjana Kamdar is passionate about and dedicated to
                      teaching and sharing knowledge. She graduated with a
                      Bachelor’s of Business Management from the University of
                      Queensland, with 5 years of teaching experience. Sanjana’s
                      aim is to make every child feel at home at PSA by
                      encouraging her students to overcome their fear of
                      speaking infront of crowds and take risks to discover
                      their ability in public speaking. She is also a registered
                      trainer with MOE to teach public speaking at PSA.
                    </p>
                  </div>
                </div>
                <div className="w-full">
                  <div className="p-5">
                    <img src="/images/sanjana-kamdar.jpg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pb-10"></div>
        </div>
      </section>

      <section id="trainer-2">
        <div className="mx-5 rounded-3xl md:mx-20">
          <div className="container mx-auto">
            <div className="pt-14">
              <div className="flex flex-col items-center border border-gray-400 border-dashed lg:space-x-10 lg:flex-row rounded-2xl">
                <div className="w-full">
                  <div className="p-5">
                    <img src="/images/Derrick-Tay.png" alt="" />
                  </div>
                </div>
                <div className="w-full">
                  <div className="p-5">
                    <div className="">
                      <h2 className="py-4 text-4xl font-bold text-center text-primary font-primary">
                        Derrick Tay
                      </h2>
                      <h3 className="text-xl font-bold text-center text-primary font-primary">
                        Chief Executive Officer
                      </h3>
                    </div>
                    <p className="py-4 text-xl font-text text-textColor">
                      Derrick is a consummate presenter & trainer with 6 years
                      of private tuition and school teaching experience. He
                      stands by methodical (step-by-step) approaches to teaching
                      communication techniques/skills, whilst adopting
                      out-of-the-box approaches to build rapport, uplift and
                      intellectually stimulate his students.
                    </p>
                    <div className="py-4 text-center">
                      <a
                        href="#derrick"
                        className="px-8 py-2 text-xl font-bold text-white rounded-md bg-btnColor"
                      >
                        READ MORE
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pb-10"></div>
        </div>
      </section>
      <div className="modal" id="zulhafni">
        <div className="modal-box bg-white">
          <p className="py-4">
            Zulhafni Zulkeflee was a Deputy Public Prosecutor with the Crime
            Division of the Attorney-General’s Chambers from 2015 to 2019, after
            graduating from the National University of Singapore, Faculty of
            Law. He has managed and argued criminal cases in both the State
            Courts and High Court in Singapore. During his time in NUS Law,
            Zulhafni participated in various local and international law mooting
            and negotiation competitions, emerging as part of the winning team
            for the Jean-Pictet International Humanitarian Law Competition in
            2015. He was a recipient of the Roger Fisher Prize for Negotiation.
            Zulhafni was also a recipient of the Public Service Commission
            Singapore Government Scholarship (Open), awarded in 2011 for the
            study of Law. As a champion speaker and experienced educator,
            Zulhafni believes in the importance of developing valuable
            communication skills. He is passionate in the areas of confidence
            building, charismatic delivery of speakers and developing young
            students to be persuasive speakers and critical thinkers. He is
            qualified under the WSQ Advanced Certificate in Learning and
            Performance (ACLP) scheme and is an SMC Accredited Mediator.
          </p>
          <div className="modal-action">
            <a href="#" className="btn">
              Close
            </a>
          </div>
        </div>
      </div>
      <div className="modal" id="derrick">
        <div className="modal-box bg-white">
          <p className="py-4">
            "Derrick Tay graduated from the Singapore Management University
            (SMU) with a Bachelor of Business Management (Cum Laude). He has 6
            years of private tuition and school teaching experience, as a
            dynamic and energetic Educator. During his time in SMU, Derrick
            attained High Distinction in Academic & Research Writing, and
            published essays for SMU Teaching References. Derrick is a
            consummate and experienced presenter & trainer with 6 years of
            private tuition and school teaching experience. He stands by
            methodical (step-by-step) approaches to teaching communication
            techniques/ skills, whilst adopting out-of-the-box approaches to
            build rapport, uplift and intellectually stimulate his students. He
            challenges students to think critically and systematically guide
            them towards achieving strong understanding of speech techniques. As
            a dynamic, energetic and experienced educator, Derrick believes that
            students learn best when they feel that they can relate to the
            trainer; and having been through the transformation from being
            introverted and diffident since young to being able to go for case
            competitions in SMU & take on 50 presentations at the tertiary
            level, Derrick strives to make learning fun & encouraging for the
            students, while maintaining the academic rigour and quality of
            training."
          </p>
          <div className="modal-action">
            <a href="#" className="btn">
              Close
            </a>
          </div>
        </div>
      </div>
      <div className="modal" id="merrell">
        <div className="modal-box bg-white">
          <p className="py-4">
            "Dr Merrell Lim is a professional speaker and holds a Doctorate in
            Pharmacy (PharmD) from Midwestern University, USA. He is a certified
            clinical research professional and previously held the appointment
            of Affiliate Professor at Purdue University. As an international
            speaker who has spoken and trained in more than 10 different
            countries, Dr Lim has over a decade of speaking and training
            experience. He is a specialist and authority in the areas of
            delivering technical presentations (how to make them engaging and
            come alive) as well as interpersonal communication and interview
            skills. Dr Lim has been training alongside our Master Trainer and
            World Champion of Public Speaking, Darren Tay for many of Darren
            Tay’s signature programmes. Currently, Dr Lim holds the appointment
            as Senior Trainer and Vice-Principal (Academic Development) at the
            Public Speaking Academy."
          </p>
          <div className="modal-action">
            <a href="#" className="btn">
              Close
            </a>
          </div>
        </div>
      </div>
      <div className="modal" id="constance">
        <div className="modal-box bg-white">
          <p className="py-4">
            "Constance Lim is a professional speaker and coach. With more than
            20 years of private wealth management experience, Constance brings
            with her a wealth of experience in interpersonal communication and
            management of public relations. On top of being an ACTA-trained and
            qualified trainer, Constance is also Mrs Singapore 2015 and the
            author of “12 Axioms of Optimal Living”. Constance enjoys inspiring
            young minds in areas of financial literacy, effective communication,
            and personal motivation. She believes in inspiring her audience with
            impactful and powerful stories as well as engaging activities to
            help them learn better and more effectively. Currently, Constance
            holds the appointment as Head-of-Department (Training) and Senior
            Trainer at the Public Speaking Academy."
          </p>
          <div className="modal-action">
            <a href="#" className="btn">
              Close
            </a>
          </div>
        </div>
      </div>
      <div className="modal" id="mugan">
        <div className="modal-box bg-white">
          <p className="py-4">
            "Mugan S/O Tayalan is a trained, corporate adventure learning
            facilitator for the past 6 years. He also possesses a wealth of
            knowledge in youth development via coaching and training for the
            past 12 years. These combined experiences allow him to relate to
            people of varying demographics and understand the real-time
            leadership, life, and academic challenges they may encounter. <br />
            Mugan’s experiences have a direct impact on his expertise in
            creating and designing a range of engaging, purposeful and enriching
            experiential activities. Besides being an ACTA-trained and qualified
            trainer, Mugan is also certified to administer D.I.S.C personality
            profiling instrument, the widely-used tool for individuals who are
            looking to gain some perspectives on their working and life
            personalities. <br />
            Mugan's energy, passion, drive and dedication towards learning and
            understanding the challenges of people make him a great trainer who
            can easily interact with people from all walks of life."
          </p>
          <div className="modal-action">
            <a href="#" className="btn">
              Close
            </a>
          </div>
        </div>
      </div>
      <div className="modal" id="imran">
        <div className="modal-box bg-white">
          <p className="py-4">
            ""Imran Tahir graduated from RMIT with a Bachelors of Science in
            Economics and Finance, and has spent many years supporting various
            organisations as a trainer and a facilitator. Having facilitated
            multiple conversations with numerous teams across different
            industries to help them achieve better clarity in moving towards
            their goals, Imran believes that the key ingredient to unlocking
            success in any endeavour is the ability to communicate and
            articulate ideas with passion and purpose! <br />
            Imran's added experience in developing and leading programs for
            diverse groups of participants from various industries, local, and
            overseas provides him with the relevant exposure to different
            communicative tools and techniques. As a contributing author of the
            publication, “Reluctant Networking- Bold Step to Connect”, Imran
            aspires to continue to educate and empower individuals and
            organisations to achieve high performance through confidence in
            speech and communication." Reply
          </p>
          <div className="modal-action">
            <a href="#" className="btn">
              Close
            </a>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
