import Footer from "../components/Footer";
import { NavLink } from "react-router-dom";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

export default function HomeBlog() {
  const [posts, setPosts] = useState([]);
  const perPage = 3;
  useEffect(() => {
    let url = `${process.env.REACT_APP_API_ROOT}/posts?per_page=${perPage}`;
    axios.get(url).then((res) => {
      const { data, headers } = res;
      //console.log("headers", headers["x-wp-totalpages"]);
      //setTotalPages(Number(headers["x-wp-totalpages"]));
      setPosts(data);
    });
  }, []);
  //console.log("posts", posts);
  return (
    <>
      {posts.map((post) => {
        //console.log(post.slug.rendered);
        const [date, time] = post.date.split("T");
        const [year, month, day] = date.split("-");

        return (
          <div key={post.id} className="w-full lg:w-1/3 p-5">
            <div className=" bg-slate-50">
              <LazyLoadImage
                src={post.featured_src["0"]}
                effect="blur"
                alt=""
                className="w-full h-auto"
              />
              <div className="p-5">
                <h2
                  className="my-6 text-xl font-medium text-purple-700 font-primary"
                  dangerouslySetInnerHTML={{
                    __html: post.title.rendered,
                  }}
                ></h2>
                <p
                  className="my-4 text-sm text-gray-500 font-sans"
                  dangerouslySetInnerHTML={{
                    __html: post.excerpt.rendered,
                  }}
                ></p>
                <div key={post.id} className="py-6">
                  <NavLink
                    className="px-4 py-2 font-bold text-purple-700 uppercase border-2 border-purple-700 border-solid rounded-lg hover:bg-purple-700 hover:text-white"
                    to={`/${year}/${month}/${day}/${post.slug}`}
                  >
                    Read More
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}
